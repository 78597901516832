import {
    INSTITUTION_ID
} from '../constants'

export const queries = {
    EUProjectsByYear: `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?year
      (COUNT(distinct ?projId) AS ?euProjects)
      (SUM(?funding) AS ?budget)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :ecProject ?proj .
      ?proj :unicsId ?projId .
      ?proj :startingYear ?year .
      ?proj :ecFrameworkProgram ?fp .
      ?fp :extendedName ?fpName .
      FILTER (?fpName = "H2020")
      ?proj :ecParticipant ?part .
      ?part :organization ?upc .
      ?part :ecContribution ?funding .
  }
  GROUP BY ?year`,
    erasmusProjectsByYear: `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?year
      (COUNT(distinct ?projId) AS ?erasmusplusProjects)
      (SUM(?funding) AS ?budget)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :erasmusplusProject ?proj .
      ?proj :identifier ?projId .
      ?proj :startingYear ?year .
      FILTER (?year >= 2014)
      ?proj :erasmusplusParticipant ?part .
      ?part :organization ?upc .
      ?part :grant ?funding .
  }
  GROUP BY ?year`,
    EUProjectsCollaboratorsTotal: (array) => `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      (COUNT(distinct ?collabId) AS ?collaborators)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :ecProject ?proj .
      ?proj :unicsId ?projId .
      ?proj :startingYear ?year .
      ${_.map(array, year => 'FILTER(?year != ' + year + ')').join('')}
      ?proj :ecFrameworkProgram ?fp .
      ?fp :extendedName ?fpName .
      FILTER (?fpName = "H2020")
      ?proj :ecParticipant ?part .
      ?part :organization ?collab .
      ?collab :unicsId ?collabId .
      FILTER (?collabId != ?upcId)
      ?part :ecActivityType ?at .
      ?at :identifier ?atCode .
      FILTER (?atCode != "PRC")
  }`,
    erasmusProjectsCollaboratorsTotal: (array) => `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      (COUNT(distinct ?collabId) AS ?collaborators)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :erasmusplusProject ?proj .
      ?proj :identifier ?projId .
      ?proj :startingYear ?year . 
      FILTER (?year >= 2014) 
      ${_.map(array, year => 'FILTER(?year != ' + year + ')').join('')}
      ?proj :erasmusplusParticipant ?part .
      ?part :organization ?collab .
      ?collab :unicsId ?collabId .
      FILTER (?collabId != ?upcId)
      ?part :isPrivateCompany ?isPrivateCompany .
      FILTER (!?isPrivateCompany)
  }`,
    EUProjectsCollaboratorsByYear: `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?year
      (COUNT(distinct ?collabId) AS ?collaborators)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :ecProject ?proj .
      ?proj :unicsId ?projId .
      ?proj :startingYear ?year .
      ?proj :ecFrameworkProgram ?fp .
      ?fp :extendedName ?fpName .
      FILTER (?fpName = "H2020")
      ?proj :ecParticipant ?part .
      ?part :organization ?collab .
      ?collab :unicsId ?collabId .
      FILTER (?collabId != ?upcId)
      ?part :ecActivityType ?at .
      ?at :identifier ?atCode .
      FILTER (?atCode != "PRC")
  }
  GROUP BY ?year`,
    erasmusProjectsCollaboratorsByYear: `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?year
      (COUNT(distinct ?collabId) AS ?collaborators)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :erasmusplusProject ?proj .
      ?proj :identifier ?projId .
      ?proj :startingYear ?year .
      FILTER (?year >= 2014)
      ?proj :erasmusplusParticipant ?part .
      ?part :organization ?collab .
      ?collab :unicsId ?collabId .
      FILTER (?collabId != ?upcId)
      ?part :isPrivateCompany ?isPrivateCompany .
      FILTER (!?isPrivateCompany)
  }
  GROUP BY ?year`,
    EUCountriesMap: `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?country
      ?alpha3
      ?year
      (COUNT(distinct ?projId) AS ?projects)
      (SUM(?upcFunding) AS ?funding)
  WHERE {
      SELECT DISTINCT ?projId ?upcFunding ?country ?alpha3 ?year
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :ecProject ?proj .
          ?proj :unicsId ?projId .
          ?proj :startingYear ?year .
          ?proj :ecFrameworkProgram ?fp .
          ?fp :extendedName ?fpName .
          FILTER (?fpName = "H2020")
          ?proj :ecParticipant ?upcPart .
          ?upcPart :organization ?upc .
          ?upcPart :ecContribution ?upcFunding .
          ?proj :ecParticipant ?part .
          ?part :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?part :country ?cou .
          ?cou :extendedName ?country .
          ?cou :alpha3 ?alpha3 .
          ?part :ecActivityType ?at .
          ?at :identifier ?atCode .
          FILTER (?atCode != "PRC")
      }
  }
  GROUP BY ?country ?alpha3 ?year`,
    erasmusCountriesMap: `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        ?country
        ?alpha3
        ?year
        (COUNT(distinct ?projId) AS ?projects)
        (SUM(?upcFunding) AS ?funding)
    WHERE {
        SELECT DISTINCT ?projId ?upcFunding ?country ?alpha3 ?year
        WHERE {
            ?upc a :Organization .
            ?upc :unicsId ?upcId .
            FILTER (?upcId = ${INSTITUTION_ID})
            ?upc :erasmusplusProject ?proj .
            ?proj :identifier ?projId .
            ?proj :startingYear ?year .
            FILTER (?year >= 2014)
            ?proj :erasmusplusParticipant ?upcPart .
            ?upcPart :organization ?upc .
            ?upcPart :grant ?upcFunding .
            ?proj :erasmusplusParticipant ?part .
            ?part :organization ?collab .
            ?collab :unicsId ?collabId .
            FILTER (?collabId != ?upcId)
            ?part :country ?cou .
            ?cou :extendedName ?country .
            ?cou :alpha3 ?alpha3 .
            ?part :isPrivateCompany ?isPrivateCompany .
            FILTER (!?isPrivateCompany)
        }
    }
    GROUP BY ?country ?alpha3 ?year`,
    collaboratorsEUProjectsByYear: `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?collaborator
      ?country
      ?year
      (COUNT(distinct ?projId) AS ?projectsOfCollaborator)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :collaboratesInH2020With ?collab .
      ?collab :extendedName ?collaborator .
      ?collab :ecProject ?proj .
      ?proj :unicsId ?projId .
      ?proj :startingYear ?year .
      ?proj :ecFrameworkProgram ?fp .
      ?fp :extendedName ?fpName .
      FILTER (?fpName = "H2020")
      ?proj :ecParticipant ?part .
      ?part :organization ?collab .
      ?part :country ?cou .
      ?cou :extendedName ?country .
      ?part :ecActivityType ?at .
      ?at :identifier ?atCode .
      FILTER (?atCode != "PRC")
  }
  GROUP BY ?collaborator ?country ?year`,
  collaboratorsErasmusProjectsByYear: `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?collaborator
      ?country
      ?year
      (COUNT(distinct ?projId) AS ?projectsOfCollaborator)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :collaboratesInErasmusPlusWith ?collab .
      ?collab :extendedName ?collaborator .
      ?collab :erasmusplusProject ?proj .
      ?proj :identifier ?projId .
      ?proj :startingYear ?year .
      FILTER (?year >= 2014)
      ?proj :erasmusplusParticipant ?part .
      ?part :organization ?collab .
      ?part :country ?cou .
      ?cou :extendedName ?country .
      ?part :isPrivateCompany ?isPrivateCompany .
      FILTER (!?isPrivateCompany)
  }
  GROUP BY ?collaborator ?country ?year`,
    sharedEUProjectsByYear: `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?collaborator
      ?country
      ?year
      (COUNT(distinct ?projId) AS ?projectsOfUPCWithCollaborator)
      (SUM(?upcFunding) AS ?fundingOfUPC)
  WHERE {
      SELECT DISTINCT ?collaborator ?country ?year ?projId ?upcFunding
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :ecProject ?proj .
          ?proj :unicsId ?projId .
          ?proj :startingYear ?year .
          ?proj :ecFrameworkProgram ?fp .
          ?fp :extendedName ?fpName .
          FILTER (?fpName = "H2020")
          ?proj :ecParticipant ?upcPart .
          ?upcPart :organization ?upc .
          ?upcPart :ecContribution ?upcFunding .
          ?proj :ecParticipant ?part .
          ?part :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?collab :extendedName ?collaborator .
          ?part :country ?cou .
          ?cou :extendedName ?country .
          ?part :ecActivityType ?at .
          ?at :identifier ?atCode .
          FILTER (?atCode != "PRC")

      }
  }
  GROUP BY ?collaborator ?country ?year`,
  sharedErasmusProjectsByYear: `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?collaborator
      ?country
      ?year
      (COUNT(distinct ?projId) AS ?projectsOfUPCWithCollaborator)
      (SUM(?upcFunding) AS ?fundingOfUPC)
  WHERE {
      SELECT DISTINCT ?collaborator ?country ?year ?projId ?upcFunding
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :erasmusplusProject ?proj .
          ?proj :identifier ?projId .
          ?proj :startingYear ?year .
          FILTER (?year >= 2014)
          ?proj :erasmusplusParticipant ?upcPart .
          ?upcPart :organization ?upc .
          ?upcPart :grant ?upcFunding .
          ?proj :erasmusplusParticipant ?part .
          ?part :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?collab :extendedName ?collaborator .
          ?part :country ?cou .
          ?cou :extendedName ?country .
          ?part :isPrivateCompany ?isPrivateCompany .
          FILTER (!?isPrivateCompany)

      }
  }
  GROUP BY ?collaborator ?country ?year`,
    countryEUProjects: (country) => `PREFIX : <http://unics.cloud/ontology#>
  SELECT 
      ?organizationName 
      ?year 
      (COUNT(distinct ?projectId) AS ?projects)  
      (SUM(?funding) AS ?totalFunding)
  WHERE {             
      ?project a :Project .         
      ?project :projectId ?projectId .          
      ?project :framework ?framework .         
      ?framework :extendedName ?frameworkName .       
      FILTER (?frameworkName = "H2020")              
      ?project :startingYear ?year .
      ?project :reifiedParticipant ?participant .   
      ?participant :ecContribution ?funding .      
      ?participant :organization ?organization.    
      ?participant :country ?country . 
      ?country :extendedName ?countryName .
      FILTER (?countryName = "${country}")         
      ?organization :extendedName ?organizationName . 
                          
  }
  GROUP BY ?organizationName ?year`,
    countrySDG: (country) => `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?sdgCode
      ?year
      (COUNT(distinct ?projId) AS ?projectsOfUPCWithCollaboratorCountry)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :ecProject ?proj .
      ?proj :unicsId ?projId .
      ?proj :startingYear ?year .
      ?proj :ecFrameworkProgram ?fp .
      ?fp :extendedName ?fpName .
      FILTER (?fpName = "H2020")
      ?proj :ecParticipant ?part .
      ?part :organization ?collab .
      ?collab :unicsId ?collabId .
      FILTER (?collabId != ?upcId)
      ?part :country ?cou .
      ?cou :extendedName ?country .
      ?part :ecActivityType ?at .
      ?at :identifier ?atCode .
      FILTER (?atCode != "PRC")
      ?proj :sdg ?sdg .
      ?sdg :shortName ?sdgCode .
      FILTER (?country = "${country}")
  }
  GROUP BY ?sdgCode ?year`,
    countryKeywords: (country) => `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?keyword
      ?year
      (COUNT(distinct ?projId) AS ?projectsOfUPCWithCollaboratorCountry)
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :ecProject ?proj .
      ?proj :unicsId ?projId .
      ?proj :startingYear ?year .
      ?proj :ecFrameworkProgram ?fp .
      ?fp :extendedName ?fpName .
      FILTER (?fpName = "H2020")
      ?proj :ecParticipant ?part .
      ?part :organization ?collab .
      ?collab :unicsId ?collabId .
      FILTER (?collabId != ?upcId)
      ?part :country ?cou .
      ?cou :extendedName ?country .
      ?part :ecActivityType ?at .
      ?at :identifier ?atCode .
      FILTER (?atCode != "PRC")
      ?proj :keyword ?kw .
      ?kw :textualValue ?keyword .
      FILTER (?country = "${country}")
  }
  GROUP BY ?keyword ?year`,
    countryProjectsTable: (country) => `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?projId 
      ?title 
      ?acronym 
      ?year 
      (?fpName AS ?framework) 
      (SUM(?upcFunding) AS ?fundingOfUPC)
  WHERE {
      SELECT DISTINCT ?projId ?title ?acronym ?year ?fpName ?upcFunding
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :ecProject ?proj .
          ?proj :unicsId ?projId .
          ?proj :startingYear ?year .
          ?proj :ecFrameworkProgram ?fp .
          ?fp :extendedName ?fpName .
          FILTER (?fpName = "H2020")
          ?proj :title ?title .
          ?proj :acronym ?acronym .
          ?proj :ecParticipant ?upcPart .
          ?upcPart :organization ?upc .
          ?upcPart :ecContribution ?upcFunding .
          ?proj :ecParticipant ?filterPart .
          ?filterPart :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?filterPart :country ?cou .
          ?cou :extendedName ?country .
          ?filterPart :ecActivityType ?at .
          ?at :identifier ?atCode .
          FILTER (?atCode != "PRC")
          FILTER (?country = "${country}")
      }
  } GROUP BY ?projId ?title ?acronym ?year ?fpName`,
  countryErasmusTable: (country) => `PREFIX : <http://unics.cloud/ontology#>
  SELECT
      ?projId 
      ?title 
      ?year 
      (SUM(?upcFunding) AS ?fundingOfUPC)
  WHERE {
      SELECT DISTINCT ?projId ?title ?year ?upcFunding
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :erasmusplusProject ?proj .
          ?proj :identifier ?projId .
          ?proj :startingYear ?year .
          FILTER (?year >= 2014)
          ?proj :title ?title .
          ?proj :erasmusplusParticipant ?upcPart .
          ?upcPart :organization ?upc .
          ?upcPart :grant ?upcFunding .
          ?proj :erasmusplusParticipant ?filterPart .
          ?filterPart :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?filterPart :country ?cou .
          ?cou :extendedName ?country .
          ?filterPart :isPrivateCompany ?isPrivateCompany .
          FILTER (!?isPrivateCompany)
          FILTER (?country = "${country}")
      }
  }
  GROUP BY ?projId ?title ?year`,
    countryProjectCollaboratorsTable: (country) => `PREFIX : <http://unics.cloud/ontology#>
  SELECT DISTINCT 
      ?projId 
      ?partner 
      ?partnerCountry
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :ecProject ?proj .
      ?proj :unicsId ?projId .
      ?proj :startingYear ?year .
      ?proj :ecFrameworkProgram ?fp .
      ?fp :extendedName ?fpName .
      FILTER (?fpName = "H2020")
      ?proj :ecParticipant ?filterPart .
      ?filterPart :organization ?collab .
      ?collab :unicsId ?collabId .
      FILTER (?collabId != ?upcId)
      ?filterPart :country ?cou .
      ?cou :extendedName ?country .
      ?filterPart :ecActivityType ?at .
      ?at :identifier ?atCode .
      FILTER (?atCode != "PRC")
      ?proj :ecParticipant ?part .
      ?part :organization ?partOrg .
      ?partOrg :extendedName ?partner .
      ?part :country ?partCou .
      ?partCou :extendedName ?partnerCountry .
      FILTER (?country = "${country}")}`,
      countryErasmusCollaboratorsTable: (country) => `PREFIX : <http://unics.cloud/ontology#>
      SELECT DISTINCT 
          ?projId 
          ?partner 
          ?partnerCountry
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :erasmusplusProject ?proj .
          ?proj :identifier ?projId .
          ?proj :startingYear ?year .
          FILTER (?year >= 2014)
          ?proj :erasmusplusParticipant ?filterPart .
          ?filterPart :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?filterPart :country ?cou .
          ?cou :extendedName ?country .
          ?filterPart :isPrivateCompany ?isPrivateCompany .
          FILTER (!?isPrivateCompany)
          ?proj :erasmusplusParticipant ?part .
          ?part :organization ?partOrg .
          ?partOrg :extendedName ?partner .
          ?part :country ?partCou .
          ?partCou :extendedName ?partnerCountry .
          FILTER (?country = "${country}")
      }
      `,
    countryProjectProgrammesTable: (country) => `PREFIX : <http://unics.cloud/ontology#>
  SELECT DISTINCT 
      ?projId 
      ?progCode
      ?programme
  WHERE {
      ?upc a :Organization .
      ?upc :unicsId ?upcId .
      FILTER (?upcId = ${INSTITUTION_ID})
      ?upc :ecProject ?proj .
      ?proj :unicsId ?projId .
      ?proj :startingYear ?year .
      ?proj :ecFrameworkProgram ?fp .
      ?fp :extendedName ?fpName .
      FILTER (?fpName = "H2020")
      ?proj :ecParticipant ?filterPart .
      ?filterPart :organization ?collab .
      ?collab :unicsId ?collabId .
      FILTER (?collabId != ?upcId)
      ?filterPart :country ?cou .
      ?cou :extendedName ?country .
      ?filterPart :ecActivityType ?at .
      ?at :identifier ?atCode .
      FILTER (?atCode != "PRC")
      ?proj :ecProgramme ?prog .
      ?prog :identifier ?progCode .
      ?prog :shortName ?programme .
      FILTER (?country = "${country}")}`,
    countryProjectInstrumentsTable: country => `PREFIX : <http://unics.cloud/ontology#>
      SELECT DISTINCT 
          ?projId 
          ?instrumentCode
          ?instrument
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :ecProject ?proj .
          ?proj :unicsId ?projId .
          ?proj :startingYear ?year .
          ?proj :ecFrameworkProgram ?fp .
          ?fp :extendedName ?fpName .
          FILTER (?fpName = "H2020")
          ?proj :ecParticipant ?filterPart .
          ?filterPart :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?filterPart :country ?cou .
          ?cou :extendedName ?country .
          ?filterPart :ecActivityType ?at .
          ?at :identifier ?atCode .
          FILTER (?atCode != "PRC")
          ?proj :instrument ?instr .
          ?instr :identifier ?instrumentCode .
          ?instr :shortName ?instrument .
          FILTER (?country = "${country}")
      }`,
      countryErasmusActionsTable: country => `PREFIX : <http://unics.cloud/ontology#>
      SELECT 
          ?projId 
          (coalesce(?keyActionName, "Not classified") AS ?action)
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :erasmusplusProject ?proj .
          ?proj :identifier ?projId .
          ?proj :startingYear ?year .
          FILTER (?year >= 2014)
          ?proj :erasmusplusParticipant ?filterPart .
          ?filterPart :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?filterPart :country ?cou .
          ?cou :extendedName ?country .
          ?filterPart :isPrivateCompany ?isPrivateCompany .
          FILTER (!?isPrivateCompany)
          optional {
              ?proj :erasmusplusKeyAction ?ka .
              ?ka :extendedName ?keyActionName .
          }
          FILTER (?country = "${country}")
      }
      GROUP BY ?projId ?keyActionName`,
    countryProjectSDGTable: country => `PREFIX : <http://unics.cloud/ontology#>
      SELECT DISTINCT 
          ?projId 
          ?sdgCode
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :ecProject ?proj .
          ?proj :unicsId ?projId .
          ?proj :startingYear ?year .
          ?proj :ecFrameworkProgram ?fp .
          ?fp :extendedName ?fpName .
          FILTER (?fpName = "H2020")
          ?proj :ecParticipant ?filterPart .
          ?filterPart :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?filterPart :country ?cou .
          ?cou :extendedName ?country .
          ?filterPart :ecActivityType ?at .
          ?at :identifier ?atCode .
          FILTER (?atCode != "PRC")
          ?proj :sdg ?sdg .
          ?sdg :shortName ?sdgCode .
          FILTER (?country = "${country}")
      }`,
    countryProjectKeywordsTable: country => `PREFIX : <http://unics.cloud/ontology#>
      SELECT DISTINCT 
          ?projId 
          ?keyword
      WHERE {
          ?upc a :Organization .
          ?upc :unicsId ?upcId .
          FILTER (?upcId = ${INSTITUTION_ID})
          ?upc :ecProject ?proj .
          ?proj :unicsId ?projId .
          ?proj :startingYear ?year .
          ?proj :ecFrameworkProgram ?fp .
          ?fp :extendedName ?fpName .
          FILTER (?fpName = "H2020")
          ?proj :ecParticipant ?filterPart .
          ?filterPart :organization ?collab .
          ?collab :unicsId ?collabId .
          FILTER (?collabId != ?upcId)
          ?filterPart :country ?cou .
          ?cou :extendedName ?country .
          ?filterPart :ecActivityType ?at .
          ?at :identifier ?atCode .
          FILTER (?atCode != "PRC")
          ?proj :keyword ?kw .
          ?kw :textualValue ?keyword .
          FILTER (?country = "${country}")
      }`,
    institutionProjectsByInstrumentYear: `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        ?instrument
        ?year
        (COUNT(distinct ?projId) AS ?projectsOfUPC)
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :ecProject ?proj .
        ?proj :unicsId ?projId .
        ?proj :startingYear ?year .
        ?proj :ecFrameworkProgram ?fp .
        ?fp :extendedName ?fpName .
        FILTER (?fpName = "H2020")
        ?proj :instrument ?instr .
        ?instr :shortName ?instrument .
    }
    GROUP BY ?instrument ?year`,
    institutionErasmusByActionYear: `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        (coalesce(?keyActionName, "Not classified") AS ?action)
        ?year
        (COUNT(distinct ?projId) AS ?projectsOfUPC)
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :erasmusplusProject ?proj .
        ?proj :identifier ?projId .
        ?proj :startingYear ?year .
        FILTER (?year >= 2014)
        optional {
            ?proj :erasmusplusKeyAction ?ka .
            ?ka :extendedName ?keyActionName .
        }
    }
    GROUP BY ?keyActionName ?year
    `,
    collaboratorProjectsByInstrumentYear: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        ?instrument
        ?year
        (COUNT(distinct ?projId) AS ?projectsOfCollaborator)
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :collaboratesInH2020With ?collab .
        ?collab :extendedName ?collaborator .
        ?collab :ecProject ?proj .
        ?proj :unicsId ?projId .
        ?proj :startingYear ?year .
        ?proj :ecFrameworkProgram ?fp .
        ?fp :extendedName ?fpName .
        FILTER (?fpName = "H2020")
        ?proj :ecParticipant ?part .
        ?part :organization ?collab .
        ?part :country ?cou .
        ?cou :extendedName ?country .
        ?part :ecActivityType ?at .
        ?at :identifier ?atCode .
        FILTER (?atCode != "PRC")
        ?proj :instrument ?instr .
        ?instr :shortName ?instrument .
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }
    GROUP BY ?instrument ?year`,
    collaboratorErasmusByActionYear: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        (coalesce(?keyActionName, "Not classified") AS ?action)
        ?year
        (COUNT(distinct ?projId) AS ?projectsOfCollaborator)
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :collaboratesInErasmusPlusWith ?collab .
        ?collab :extendedName ?collaborator .
        ?collab :erasmusplusProject ?proj .
        ?proj :identifier ?projId .
        ?proj :startingYear ?year .
        FILTER (?year >= 2014)
        ?proj :erasmusplusParticipant ?part .
        ?part :organization ?collab .
        ?part :country ?cou .
        ?cou :extendedName ?country .
        ?part :isPrivateCompany ?isPrivateCompany .
        FILTER (!?isPrivateCompany)
        optional {
            ?proj :erasmusplusKeyAction ?ka .
            ?ka :extendedName ?keyActionName .
        }
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }
    GROUP BY ?keyActionName ?year`,
    collaborationProjectsByInstrumentYear: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        ?instrument
        ?year
        (COUNT(distinct ?projId) AS ?projectsOfUPCWithCollaborator)
        (SUM(?upcFunding) AS ?fundingOfUPC)
    WHERE {
        SELECT DISTINCT ?instrument ?year ?projId ?upcFunding
        WHERE {
            ?upc a :Organization .
            ?upc :unicsId ?upcId .
            FILTER (?upcId = ${INSTITUTION_ID})
            ?upc :ecProject ?proj .
            ?proj :unicsId ?projId .
            ?proj :startingYear ?year .
            ?proj :ecFrameworkProgram ?fp .
            ?fp :extendedName ?fpName .
            FILTER (?fpName = "H2020")
            ?proj :ecParticipant ?upcPart .
            ?upcPart :organization ?upc .
            ?upcPart :ecContribution ?upcFunding .
            ?proj :ecParticipant ?part .
            ?part :organization ?collab .
            ?collab :extendedName ?collaborator .
            ?part :country ?cou .
            ?cou :extendedName ?country .
            ?part :ecActivityType ?at .
            ?at :identifier ?atCode .
            FILTER (?atCode != "PRC")
            ?proj :instrument ?instr .
            ?instr :shortName ?instrument .
            FILTER (?collaborator = "${collaborator}")
            FILTER (?country = "${country}")
        }
    }
    GROUP BY ?instrument ?year`,
    collaborationErasmusByActionYear: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        (coalesce(?keyActionName, "Not classified") AS ?action)
        ?year
        (COUNT(distinct ?projId) AS ?projectsOfUPCWithCollaborator)
        (SUM(?upcFunding) AS ?fundingOfUPC)
    WHERE {
        SELECT DISTINCT ?keyActionName ?year ?projId ?upcFunding
        WHERE {
            ?upc a :Organization .
            ?upc :unicsId ?upcId .
            FILTER (?upcId = ${INSTITUTION_ID})
            ?upc :erasmusplusProject ?proj .
            ?proj :identifier ?projId .
            ?proj :startingYear ?year .
            FILTER (?year >= 2014)
            ?proj :erasmusplusParticipant ?upcPart .
            ?upcPart :organization ?upc .
            ?upcPart :grant ?upcFunding .
            ?proj :erasmusplusParticipant ?part .
            ?part :organization ?collab .
            ?collab :extendedName ?collaborator .
            ?part :country ?cou .
            ?cou :extendedName ?country .
            ?part :isPrivateCompany ?isPrivateCompany .
            FILTER (!?isPrivateCompany)
            optional {
                ?proj :erasmusplusKeyAction ?ka .
                ?ka :extendedName ?keyActionName .
            }
            FILTER (?collaborator = "${collaborator}")
            FILTER (?country = "${country}")
        }
    }
    GROUP BY ?keyActionName ?year`,
    institutionSDG: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        ?sdgCode
        ?year
        (COUNT(distinct ?projId) AS ?projectsOfUPCWithCollaborator)
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :ecProject ?proj .
        ?proj :unicsId ?projId .
        ?proj :startingYear ?year .
        ?proj :ecFrameworkProgram ?fp .
        ?fp :extendedName ?fpName .
        FILTER (?fpName = "H2020")
        ?proj :ecParticipant ?part .
        ?part :organization ?collab .
        ?collab :extendedName ?collaborator .
        ?part :country ?cou .
        ?cou :extendedName ?country .
        ?part :ecActivityType ?at .
        ?at :identifier ?atCode .
        FILTER (?atCode != "PRC")
        ?proj :sdg ?sdg .
        ?sdg :shortName ?sdgCode .
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }
    GROUP BY ?sdgCode ?year`,
    institutionKeywords: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        ?keyword
        ?year
        (COUNT(distinct ?projId) AS ?projectsOfUPCWithCollaborator)
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :ecProject ?proj .
        ?proj :unicsId ?projId .
        ?proj :startingYear ?year .
        ?proj :ecFrameworkProgram ?fp .
        ?fp :extendedName ?fpName .
        FILTER (?fpName = "H2020")
        ?proj :ecParticipant ?part .
        ?part :organization ?collab .
        ?collab :extendedName ?collaborator .
        ?part :country ?cou .
        ?cou :extendedName ?country .
        ?part :ecActivityType ?at .
        ?at :identifier ?atCode .
        FILTER (?atCode != "PRC")
        ?proj :keyword ?kw .
        ?kw :textualValue ?keyword .
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }
    GROUP BY ?keyword ?year`,
    institutionProjectsTable: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        ?projId 
        ?title 
        ?acronym 
        ?year 
        (?fpName AS ?framework) 
        (SUM(?upcFunding) AS ?fundingOfUPC)
    WHERE {
        SELECT DISTINCT ?projId ?title ?acronym ?year ?fpName ?upcFunding
        WHERE {
            ?upc a :Organization .
            ?upc :unicsId ?upcId .
            FILTER (?upcId = ${INSTITUTION_ID})
            ?upc :ecProject ?proj .
            ?proj :unicsId ?projId .
            ?proj :startingYear ?year .
            ?proj :ecFrameworkProgram ?fp .
            ?fp :extendedName ?fpName .
            FILTER (?fpName = "H2020")
            ?proj :title ?title .
            ?proj :acronym ?acronym .
            ?proj :ecParticipant ?upcPart .
            ?upcPart :organization ?upc .
            ?upcPart :ecContribution ?upcFunding .
            ?proj :ecParticipant ?filterPart .
            ?filterPart :organization ?collab .
            ?collab :extendedName ?collaborator .
            ?filterPart :country ?cou .
            ?cou :extendedName ?country .
            ?filterPart :ecActivityType ?at .
            ?at :identifier ?atCode .
            FILTER (?atCode != "PRC")    
            FILTER (?collaborator = "${collaborator}")
            FILTER (?country = "${country}")
        }
    }
    GROUP BY ?projId ?title ?acronym ?year ?fpName`,
    institutionErasmusTable: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT
        ?projId 
        ?title 
        ?year 
        (SUM(?upcFunding) AS ?fundingOfUPC)
    WHERE {
        SELECT DISTINCT ?projId ?title ?year ?upcFunding
        WHERE {
            ?upc a :Organization .
            ?upc :unicsId ?upcId .
            FILTER (?upcId = ${INSTITUTION_ID})
            ?upc :erasmusplusProject ?proj .
            ?proj :identifier ?projId .
            ?proj :startingYear ?year .
            FILTER (?year >= 2014)
            ?proj :title ?title .
            ?proj :erasmusplusParticipant ?upcPart .
            ?upcPart :organization ?upc .
            ?upcPart :grant ?upcFunding .
            ?proj :erasmusplusParticipant ?filterPart .
            ?filterPart :organization ?collab .
            ?collab :extendedName ?collaborator .
            ?filterPart :country ?cou .
            ?cou :extendedName ?country .
            ?filterPart :isPrivateCompany ?isPrivateCompany .
            FILTER (!?isPrivateCompany)
            FILTER (?collaborator = "${collaborator}")
            FILTER (?country = "${country}")
        }
    }
    GROUP BY ?projId ?title ?year`,
    institutionProjectCollaboratorsTable: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT DISTINCT 
        ?projId 
        ?partner 
        ?partnerCountry
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :ecProject ?proj .
        ?proj :unicsId ?projId .
        ?proj :startingYear ?year .
        ?proj :ecFrameworkProgram ?fp .
        ?fp :extendedName ?fpName .
        FILTER (?fpName = "H2020")
        ?proj :ecParticipant ?filterPart .
        ?filterPart :organization ?collab .
        ?collab :extendedName ?collaborator .
        ?filterPart :country ?cou .
        ?cou :extendedName ?country .
        ?filterPart :ecActivityType ?at .
        ?at :identifier ?atCode .
        FILTER (?atCode != "PRC")    
        ?proj :ecParticipant ?part .
        ?part :organization ?partOrg .
        ?partOrg :extendedName ?partner .
        ?part :country ?partCou .
        ?partCou :extendedName ?partnerCountry .
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }`,
    institutionErasmusCollaboratorsTable: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT DISTINCT 
        ?projId 
        ?partner 
        ?partnerCountry
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :erasmusplusProject ?proj .
        ?proj :identifier ?projId .
        ?proj :startingYear ?year .
        FILTER (?year >= 2014)
        ?proj :erasmusplusParticipant ?filterPart .
        ?filterPart :organization ?collab .
        ?collab :extendedName ?collaborator .
        ?filterPart :country ?cou .
        ?cou :extendedName ?country .
        ?filterPart :isPrivateCompany ?isPrivateCompany .
        FILTER (!?isPrivateCompany)
        ?proj :erasmusplusParticipant ?part .
        ?part :organization ?partOrg .
        ?partOrg :extendedName ?partner .
        ?part :country ?partCou .
        ?partCou :extendedName ?partnerCountry .
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }`,
    institutionProjectProgrammesTable: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT DISTINCT 
        ?projId 
        ?progCode
        ?programme
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :ecProject ?proj .
        ?proj :unicsId ?projId .
        ?proj :startingYear ?year .
        ?proj :ecFrameworkProgram ?fp .
        ?fp :extendedName ?fpName .
        FILTER (?fpName = "H2020")
        ?proj :ecParticipant ?filterPart .
        ?filterPart :organization ?collab .
        ?collab :extendedName ?collaborator .
        ?filterPart :country ?cou .
        ?cou :extendedName ?country .
        ?filterPart :ecActivityType ?at .
        ?at :identifier ?atCode .
        FILTER (?atCode != "PRC")
        ?proj :ecProgramme ?prog .
        ?prog :identifier ?progCode .
        ?prog :shortName ?programme .
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }`,
    institutionProjectInstrumentsTable: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT DISTINCT 
        ?projId 
        ?instrumentCode
        ?instrument
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :ecProject ?proj .
        ?proj :unicsId ?projId .
        ?proj :startingYear ?year .
        ?proj :ecFrameworkProgram ?fp .
        ?fp :extendedName ?fpName .
        FILTER (?fpName = "H2020")
        ?proj :ecParticipant ?filterPart .
        ?filterPart :organization ?collab .
        ?collab :extendedName ?collaborator .
        ?filterPart :country ?cou .
        ?cou :extendedName ?country .
        ?filterPart :ecActivityType ?at .
        ?at :identifier ?atCode .
        FILTER (?atCode != "PRC")
        ?proj :instrument ?instr .
        ?instr :identifier ?instrumentCode .
        ?instr :shortName ?instrument .
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }`,
    institutionErasmusActionsTable: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT 
        ?projId 
        (coalesce(?keyActionName, "Not classified") AS ?action)
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :erasmusplusProject ?proj .
        ?proj :identifier ?projId .
        ?proj :startingYear ?year .
        FILTER (?year >= 2014)
        ?proj :erasmusplusParticipant ?filterPart .
        ?filterPart :organization ?collab .
        ?collab :extendedName ?collaborator .
        ?filterPart :country ?cou .
        ?cou :extendedName ?country .
        ?filterPart :isPrivateCompany ?isPrivateCompany .
        FILTER (!?isPrivateCompany)    
        optional {
            ?proj :erasmusplusKeyAction ?ka .
            ?ka :extendedName ?keyActionName .
        }
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }
    GROUP BY ?projId ?keyActionName`,
    institutionProjectSDGTable: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT DISTINCT 
        ?projId 
        ?sdgCode
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :ecProject ?proj .
        ?proj :unicsId ?projId .
        ?proj :startingYear ?year .
        ?proj :ecFrameworkProgram ?fp .
        ?fp :extendedName ?fpName .
        FILTER (?fpName = "H2020")
        ?proj :ecParticipant ?filterPart .
        ?filterPart :organization ?collab .
        ?collab :extendedName ?collaborator .
        ?filterPart :country ?cou .
        ?cou :extendedName ?country .
        ?filterPart :ecActivityType ?at .
        ?at :identifier ?atCode .
        FILTER (?atCode != "PRC")
        ?proj :sdg ?sdg .
        ?sdg :shortName ?sdgCode .
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }`,
    institutionProjectKeywordsTable: (collaborator, country) => `PREFIX : <http://unics.cloud/ontology#>
    SELECT DISTINCT 
        ?projId 
        ?keyword
    WHERE {
        ?upc a :Organization .
        ?upc :unicsId ?upcId .
        FILTER (?upcId = ${INSTITUTION_ID})
        ?upc :ecProject ?proj .
        ?proj :unicsId ?projId .
        ?proj :startingYear ?year .
        ?proj :ecFrameworkProgram ?fp .
        ?fp :extendedName ?fpName .
        FILTER (?fpName = "H2020")
        ?proj :ecParticipant ?filterPart .
        ?filterPart :organization ?collab .
        ?collab :extendedName ?collaborator .
        ?filterPart :country ?cou .
        ?cou :extendedName ?country .
        ?filterPart :ecActivityType ?at .
        ?at :identifier ?atCode .
        FILTER (?atCode != "PRC")
        ?proj :keyword ?kw .
        ?kw :textualValue ?keyword .
        FILTER (?collaborator = "${collaborator}")
        FILTER (?country = "${country}")
    }
    `
}
