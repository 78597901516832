
export default class DatatypeLabel {

    parse() {
        throw new Error('Not implemented');
    }

    unparse() {
        throw new Error('Not implemented');
    }
};
