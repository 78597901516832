export const barsInstrumentsSpec = {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "description": "A bar chart that sorts the y-values by the x-values.",
    "background": "white",
    "padding": 5,
    "width": 200,
    "style": "cell",
    "signals": [
        { "name": "y_step", "value": 17 },
        {
            "name": "height",
            "update": "bandspace(domain('y').length, 0.1, 0.05) * y_step"
        },
        { "name": "dataTotal", "value": null },
        { "name": "dataUpdated", "value": [] }
    ],
    "data": [
        {
            "name": "source_0",
            // "url": "./data/population.json",
            "values": {"signal": "dataUpdated"},
            "format": { "type": "json" }
            // "transform": [{ "type": "filter", "expr": "datum.year == 2000" }]
        },
        {
            "name": "data_0",
            "source": "source_0",
            "transform": [
                {
                    "type": "aggregate",
                    "groupby": ["instrument"],
                    "ops": ["sum"],
                    "fields": ["count"],
                    "as": ["sum_projects"]
                },
                {
                    "type": "filter",
                    "expr": "isValid(datum[\"sum_projects\"]) && isFinite(+datum[\"sum_projects\"])"
                }
            ]
        }
    ],
    "marks": [
        {
            "name": "marks",
            "type": "rect",
            "style": ["bar"],
            "from": { "data": "data_0" },
            "encode": {
                "update": {
                    "fill": { "value": "#4c78a8" },
                    "ariaRoleDescription": { "value": "bar" },
                    "x": { "scale": "x", "field": "sum_projects" },
                    "x2": { "scale": "x", "value": 0 },
                    "y": { "scale": "y", "field": "instrument" },
                    "height": { "scale": "y", "band": 1 },
                    "tooltip": {
                        "signal": "{title: datum.instrument, 'H2020 projects' : datum.sum_projects}"
                    }
                }
            }
        },
        {
            "name": "layer_1_marks",
            "type": "text",
            "style": ["text"],
            "from": { "data": "data_0" },
            "interactive": false,
            "encode": {
              "update": {
                "opacity": { "value": 0.9 },
                "fill": { 
                    "signal": "datum[\"sum_projects\"] > dataTotal * 0.1 ? 'white' : 'black'" 
                },

                "x": {
                  "signal": "scale(\"x\", datum[\"sum_projects\"])"
                },
                "y": { "scale": "y", "field": "instrument", "band": 0.5 },
                // "text": { "signal": "datum['type'] == 'Shared' ? format(datum[\"sum_projects\"] / upc_total, ',.1%') + ' ' + format(datum[\"sum_projects\"], \"\") + ' projects' : ''" },
                "text": { "signal": "format(datum[\"sum_projects\"], ',') + ' (' + format(datum[\"sum_projects\"] / dataTotal, ',.1%') + ')'" },
                "align": {
                    "signal": "datum[\"sum_projects\"] > dataTotal * 0.1 ? 'right' : 'left'" 
                 },
                 "dx": {
                    "signal": "datum[\"sum_projects\"] > dataTotal * 0.1 ? -6 : 6" 
                 },
                "baseline": { "value": "middle" }
              }
            }
          }
    ],
    "scales": [
        {
            "name": "x",
            "type": "linear",
            "domain": { "data": "data_0", "field": "sum_projects" },
            "range": [0, { "signal": "width" }],
            "nice": true,
            "zero": true
        },
        {
            "name": "y",
            "type": "band",
            "domain": {
                "data": "source_0",
                "field": "instrument",
                "sort": { "op": "sum", "field": "count", "order": "descending" }
            },
            "range": { "step": { "signal": "y_step" } },
            "paddingInner": 0.1,
            "paddingOuter": 0.05
        }
    ],
    "axes": [
        { "scale": "y", "orient": "left", "grid": false, "title": "", "zindex": 0, "labelLimit": 300 }
    ]
}
