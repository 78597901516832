import React, { Component } from 'react';
// external
// import * as _ from 'lodash';
import * as d3 from 'd3';
import memoize from 'memoize-one';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import * as XLSX from 'xlsx'
// components
// specs
// styles
import './index.scss';

import {
    SDG_TITLES,
    INSTITUTION_NAME
} from '../../common/constants'


class TableProjects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: undefined,
            columns: memoize(clickHandler => [
                {
                    name: 'Title',
                    selector: row => row['title'],
                    sortable: true,
                    wrap: true,
                    grow: 2
                },
                // {
                //     name: 'Programme',
                //     selector: row => row['programme'],
                //     sortable: true,
                //     wrap: true,
                //     grow: 2
                // },
                {
                    name: 'Instrument',
                    selector: row => row['instrument'] ? row['instrument'] : row['action'],
                    wrap: true,
                    sortable: true
                },
                // {
                //     name: 'Framework',
                //     selector: row => row['framework'],
                //     sortable: true,
                //     grow: 0
                // },
                {
                    name: 'Funding(€)',
                    selector: row => Math.round(row['fundingOfUPC']),
                    // selector: row => row['fundingOfUPC'],
                    sortable: true,
                    right: true
                },
                {
                    name: 'Year',
                    selector: row => row['year'],
                    sortable: true,
                    grow: 0,
                    right: true
                }
            ])
        }
    }

    handleButtonClick = () => {
        // console.log('clicked');
    };

    handleSelectedUniversity = (row) => {
        this.props.setUniversityID(row.idInstitution);
        setTimeout(this.navigateToUniversity(), 3000);
    }

    downloadExcel = (data) => {

        const currentCollaborator = this.props.currentCountry
            ? this.props.currentCountry.trim().toLowerCase().replace(/\s+/g, '-')
            : this.props.currentInstitution.trim().toLowerCase().replace(/\s+/g, '-')

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "upc-collaborations-" + currentCollaborator + ".xlsx");
    };


    render() {
        // console.log("LIST_UNIVERSITIES")
        // console.log("THIS.PROPS", this.props)

        const { columns } = this.state;
        const { data } = this.props;

        let ExpandedComponent;
        ExpandedComponent = ({ data }) => {
            // console.log("dataTableData", data);

            const html = <div className='container py-4 bg-light'>
                <div className='row'>
                    {
                        data['instrument']
                        && <div className="col col-3">
                            <h6 className='text-uppercase'>Funding framework</h6>
                            <p>{data['framework']}</p>
                            <h6 className='text-uppercase'>Programme</h6>
                            <p>{data['programme']}</p>
                        </div>
                    }
                    {
                        data['SDG'].length > 0
                        && <div className="col col-3">
                            <h6 className='text-uppercase'>SDGs</h6>
                            {
                                _.map(data['SDG'], (sdg, index) => { return <p key={index} className='mb-0'>{sdg['sdgCode']}, {SDG_TITLES[sdg['sdgCode']]}</p> })
                            }
                        </div>
                    }
                    {
                        data['keywords'].length > 0
                        && <div className="col">
                            <h6 className='text-uppercase'>Keywords</h6>
                            {
                                _.map(data['keywords'], (keyword, index) => { return <span key={index} className='d-inline-block me-1 mb-1 border bg-white px-1'>{keyword['keyword']}</span> })
                            }
                        </div>
                    }
                    <div className="col col-12 pt-2 mt-4">
                        <h6 className='text-uppercase'>Project partners ({data['collaborators'].length})</h6>
                        {
                            _.map(data['collaborators'], (collaborator, index) => {
                                return <span key={index}
                                    className={`me-1 mb-1 px-1 border bg-white ${(this.props.currentInstitution === collaborator['partner'] || this.props.currentCountry === collaborator['partnerCountry']) ? 'text-primary' : 'text-muted'} ${collaborator['partner'] === INSTITUTION_NAME ? 'd-none' : 'd-inline-block'}`}>
                                    {collaborator['partner']} ({collaborator['partnerCountry']})
                                </span>
                            })
                        }
                    </div>
                </div>
            </div>
            return html
        };

        return <div className="list-universities">
            <div className="row">
                <div className="col">
                    <h3 className='title pb-1'>List of projects in collaboration</h3>
                    <p>List of UPC projects in collaboration with {this.props.currentCountry ? this.props.currentCountry : this.props.currentInstitution}. Column “Funding” refers to the funding received by UPC. Projects can be expanded by clicking on the left-side arrow. Projects can also be downloaded in XLS format by clicking on the “Download in XLS format” button.</p>
                </div>
                <div className="col col-auto text-end pt-4">
                    <button className='btn btn-secondary me-2' onClick={() => this.downloadExcel(data)}>
                        Download in xls format
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {
                        data && <DataTable
                            columns={columns(this.handleButtonClick)}
                            data={data}
                            // dense
                            defaultSortFieldId={1}
                            pagination
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                        />
                    }
                </div>
            </div>

        </div>
    }
}

export default TableProjects;