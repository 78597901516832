import React, { Component } from 'react';
import './index.scss';
import * as d3 from 'd3';

class CountryInstitutionsList extends Component {

    handleCollaborator = (name, country) => this.props.setCollaborator(name, country);

    render() {

        const { data } = this.props;

        return <div className="col">

            <h3 className="title pb-1">Collaborators list</h3>
            <p>Main actors in collaboration with UPC in {data['country']}. Shows number of UPC projects in collaboration with the actor and % of projects over the total for UPC.</p>
            <div className="collaborators-scrolling-list">
                {
                    _.map(data['countryActors'], (actor, index) => <div key={index} className={`item row border-top py-2`}>

                        <div className="col">
                            <div className="row"
                                onClick={() => this.handleCollaborator(actor['name'], data['country'])}
                            >
                                <div className="col text-truncate">{actor['name']}</div>
                                {
                                    actor['upcCollaborationProjects'] > 0 &&
                                    <div className="col col-12 text-primary">
                                        <p className='m-0'>
                                            {d3.format(',.2%')(actor['upcPercentageCollaboration'])} ({d3.format(',')(actor['upcCollaborationProjects']) + ' ' + (actor['upcCollaborationProjects'] > 1 ? (this.props.entity === 'erasmus' ? 'Erasmus+ projects' : 'H2020 projects') : this.props.entity === 'erasmus' ? 'Erasmus+ project' : 'H2020 project')})
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>)
                }
            </div>


        </div>
    }
}

export default CountryInstitutionsList;