import React, { Component } from 'react';
import IndicatorLineChart from './indicatorLineChart'
import './index.scss';

// import { IoIosArrowRoundUp } from "react-icons/io";
// import { IoIosArrowRoundDown } from "react-icons/io";


class IndicatorChart extends Component {

    render() {

        const { size, label, units, data, chart, config } = this.props;

        if (!config) {
            return <div className={`indicator-chart indicator-chart-${size}`}>
                <p className="indicator-chart_label">{label}</p>
                <p className="indicator-chart_value">
                    {Object.keys(data).length !== 0 ? data.value : 0} {units}
                </p>
                {
                    config.secondValue &&
                    <p><small>{config.secondValue}</small></p>
                }
                {
                    chart &&
                    <IndicatorLineChart
                        data={chart}
                    ></IndicatorLineChart>
                }

                {/*
                <p className={`percentage text-center ${data.growing ? 'text-success' : 'text-danger'}`}>
                    {data.growing ? <IoIosArrowRoundUp></IoIosArrowRoundUp> : <IoIosArrowRoundDown></IoIosArrowRoundDown>}
                    {data.percentage}%
                </p> */}
            </div>

        } else {
            return <div className={`indicator-chart indicator-chart-${config.size} ${config.alignment ? 'indicator-chart-' + config.alignment : 'indicator-chart-left'}`}>
                <p className="indicator-chart_label">{config.label}</p>
                <p className="indicator-chart_value">
                    {config.value ? config.value : 0} {config.units}
                </p>
                {
                    config.secondValue &&
                    <p className='m-0'>{config.secondValue}</p>
                }
                {
                    config.chart &&
                    <IndicatorLineChart
                        data={config.chart}
                        dimension={config.units}
                    ></IndicatorLineChart>
                }
            </div>
        }

    }
}

export default IndicatorChart;