export const mapSpec = {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "description": "An interactive world map supporting pan and zoom.",
    "signals": [
      {
        "name": "onElementHovered",
        "on": [
          {
            "events": "@country_shape:mouseover",
            "update": "datum.label"
          },
          {
            "events": "@country_shape:mouseout",
            "update": "null"
          }
        ]
      },
      {
        "name": "onElementClicked",
        "on": [
          {
            "events": "@country_shape:click",
            "update": "datum.label"
          }
        ]
      },
      {
        "name": "scale",
        "value": 200,
        "on": [
          {
            "events": { "type": "wheel", "consume": true },
            "update": "clamp(scale * pow(1.0005, -event.deltaY * pow(16, event.deltaMode)), 150, 3000)"
          }
        ]
      },
      {
        "name": "angles",
        "value": [0, 0],
        "on": [{ "events": "mousedown", "update": "[rotateX, centerY]" }]
      },
      {
        "name": "cloned",
        "value": null,
        "on": [{ "events": "mousedown", "update": "copy('projection')" }]
      },
      {
        "name": "start",
        "value": null,
        "on": [{ "events": "mousedown", "update": "invert(cloned, xy())" }]
      },
      {
        "name": "drag",
        "value": null,
        "on": [
          {
            "events": "[mousedown, window:mouseup] > window:mousemove",
            "update": "invert(cloned, xy())"
          }
        ]
      },
      {
        "name": "delta",
        "value": null,
        "on": [
          {
            "events": { "signal": "drag" },
            "update": "[drag[0] - start[0], start[1] - drag[1]]"
          }
        ]
      },
      {
        "name": "rotateX",
        "value": 0,
        "on": [{ "events": { "signal": "delta" }, "update": "angles[0] + delta[0]" }]
      },
      { "name": "tx", "update": "width * 0.5" },
      { "name": "ty", "update": "height * 0.6" },
      {
        "name": "centerY",
        "value": 0,
        "on": [
          {
            "events": { "signal": "delta" },
            "update": "clamp(angles[1] + delta[1], -60, 60)"
          }
        ]
      },
      { "name": "dataUpdated", "value": [] },
      { "name": "entity", "value": 'funding' }
    ],
    "projections": [
      {
        "name": "projection",
        "type": "mercator",
        "scale": { "signal": "scale" },
        "rotate": [{ "signal": "rotateX" }, 0, 0],
        "center": [0, { "signal": "centerY" }],
        "translate": [{ "signal": "tx" }, { "signal": "ty" }]
      }
    ],
    "data": [
      {
        "name": "data",
        "values": { "signal": "dataUpdated" },
        "transform": [
          { 
            "type": "extent", 
            "field": {"signal": "entity"}, 
            "signal": "value" 
          }
        ]
      },
      {
        "name": "world",
        "url": "data/map/world_by_iso_min_topo.json",
        "format": { "type": "topojson", "feature": "worldByIso" },
        "transform": [
          {
            "type": "lookup",
            "from": "data",
            "key": "code",
            "fields": ["id"],
            "values": ["value", "funding", "label"]
          }
        ]
      }
    ],
    "scales": [
      {
        "name": "color",
        "type": "linear",
        "domain": { "signal": "value" },
        "range": ["#F2F2F2", "#097AC2", "#00264D"]
      }
    ],
    "marks": [
      {
        "type": "shape",
        "name": "country_shape",
        "from": { "data": "world" },
        "encode": {
          "enter": { 
            "strokeWidth": { "value": 0.5 }, 
            "stroke": { "value": "#bbb" } 
          },
          "update": {
            "fill": [
              {
                "test": "datum.label === null || onElementHovered !== datum.label",
                "scale": "color",
                "field": {"signal": "entity"}
              },
              {
                "value": "#dd6600"
              }
            ],
            "tooltip": {
              "signal": "{title: datum.label, Collaborations : (datum.value ? datum.value : 'none'), Funding : datum.funding ? format(datum.funding, ',.2r') + '€' : 'none'}"
            }
          }
        },
        "transform": [{ "type": "geoshape", "projection": "projection" }]
      }
    ]
  };