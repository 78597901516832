import React, { Component } from 'react';
import './index.scss';
import * as d3 from 'd3';

class FocusCollaborations extends Component {

    constructor() {
        super();
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }


    handleInstitution = (country, institution) => {
        // this.props.setSelectedCountry(country);
        this.props.setCollaborator(institution, country);
        return true;
    }

    render() {

        console.log("FocusCollaborations");
        console.log("THIS.PROPS", this.props);

        let collaborationProjects = _.filter(this.props.data, obj => obj.segment === 0);

        // Sort by entity
        collaborationProjects = _.orderBy(collaborationProjects, [this.props.currentValue], ['desc']);
        console.log("collaborationProjects", collaborationProjects);

        collaborationProjects = this.props.hoveredCountry
            ? collaborationProjects.slice(0, 10)
            : collaborationProjects

        return <div className="collaboration-chart">
            <h3 className='title pb-1'>Ranking of institutions</h3>
            <p>Main actors in collaboration with UPC in the selected region. Shows {this.props.currentValue === 'funding' ? 'total financing for UPC projects' : 'number of UPC projects'} in collaboration with the institution and % of {this.props.currentValue === 'funding' ? 'financing' : 'projects'} over the total for UPC.</p>
            <div className="scrolling-chart">
                {
                    _.map(collaborationProjects, (obj, index) => {
                        return <div
                            key={index}
                            className={`item row position-relative pb-1 pb-4 ${(index % 2 == 0) ? "bg-light" : "bg-white"}`}
                            onClick={() => this.handleInstitution(obj['country'], obj['institution'])}>
                            {
                                this.props.currentValue === 'funding'
                                    ? <div className="bg-primary bar" style={{ width: d3.format(',.1%')((obj['funding'] * 2) / this.props.totalFundingUPC) }}>
                                        <p className='mb-0 text-primary percentage text-nowrap'>
                                            {d3.format(',.2r')(obj['funding'])}€ ({d3.format(',.1%')((obj['funding']) / this.props.totalFundingUPC)})
                                        </p>
                                    </div>
                                    : <div className="bg-primary bar" style={{ width: d3.format(',.1%')((obj['projects'] * 2) / this.props.totalProjectsUPC) }}>
                                        <p className='mb-0 text-primary percentage text-nowrap'>
                                            {d3.format(',')(obj['projects'])} projects ({d3.format(',.1%')((obj['projects']) / this.props.totalProjectsUPC)})
                                        </p>
                                    </div>
                            }
                            <div className="col col-10 position-relative">
                                <p className='text-truncate mb-0'>
                                    {obj['institution']}
                                </p>
                            </div>
                            <div className="col col-2 ms-auto position-relative pt-1 text-end">
                                <small className='d-block text-muted text-truncate'>{obj['country']}</small>
                            </div>
                        </div>
                    })
                }

            </div>


        </div>
    }
}

export default FocusCollaborations;