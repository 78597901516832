
// common config for the vega vizs
export const VEGA_CONFIG = {
  title: {
    fontFamily: 'Zilla Slab'
  }
};
export const INSTITUTION_ID = '2450';
export const INSTITUTION_NAME = 'Universitat Politècnica de Catalunya';
export const SDG_PALETTE = {
  'SDG 1': 'rgb(229,36,59)',
  'SDG 2': 'rgb(221,166,58)',
  'SDG 3': 'rgb(76,159,56)',
  'SDG 4': 'rgb(197,25,45)',
  'SDG 5': 'rgb(255,58,33)',
  'SDG 6': 'rgb(38,189,226)',
  'SDG 7': 'rgb(252,195,11)',
  'SDG 8': 'rgb(162,25,66)',
  'SDG 9': 'rgb(253,105,37)',
  'SDG 10': 'rgb(221,19,103)',
  'SDG 11': 'rgb(253,157,36)',
  'SDG 12': 'rgb(191,139,46)',
  'SDG 13': 'rgb(63,126,68)',
  'SDG 14': 'rgb(10,151,217 )',
  'SDG 15': 'rgb(86,192,43)',
  'SDG 16': 'rgb(0,104,157)',
  'SDG 17': 'rgb(25,72,106)',
}
export const SDG_TITLES = {
  'SDG 1': 'No Poverty',
  'SDG 2': 'Zero Hunger',
  'SDG 3': 'Good Health and Well-being',
  'SDG 4': 'Quality Education',
  'SDG 5': 'Gender Equality',
  'SDG 6': 'Clean Water and Sanitation',
  'SDG 7': 'Affordable and Clean Energy',
  'SDG 8': 'Decent Work and Economic Growth',
  'SDG 9': 'Industry, Innovation and Infrastructure',
  'SDG 10': 'Reduced Inequality',
  'SDG 11': 'Sustainable Cities and Communities',
  'SDG 12': 'Responsible Consumption and Production',
  'SDG 13': 'Climate Action',
  'SDG 14': 'Life Below Water',
  'SDG 15': 'Life on Land',
  'SDG 16': 'Peace and Justice Strong Institutions',
  'SDG 17': 'Partnerships to achieve the goal'
}

export const DEPLOY_URL = '';
export const DEPLOY_FOLDER = '';
export const LOREM_IPSUM = "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?";

export const RANKING_IDS = {
  ARWU: 'ARWU',
  ARWUS: 'ARWUS',
  GRAS: 'GRAS',
  NTU: 'NTU',
  QS: 'QS',
  THE: 'THE'

}
export const ALLIANCE_META_DATA = [
  {
    "id": 13,
    "label": "Advanced Technology Higher Education Network Alliance",
    "url": "https://eua.eu/101-projects/533-athena.html"
  },
  {
    "id": 14,
    "label": "Aurora European University",
    "url": "http://alliance.aurora-network.global/what-we-do"
  },
  {
    "id": 16,
    "label": "Circle U. European University",
    "url": "https://www.circle-u.eu/about/mission/"
  },
  {
    "id": 20,
    "label": " Engaged and Entrepreneurial European University as Driver for European Smart and Sustainable Regions",
    "url": "https://eudres.eu/what-we-do"
  },
  {
    "id": 21,
    "label": "European Campus of City-Universities",
    "url": "https://ec2u.eu/what-is-ec2u"
  },
  {
    "id": 24,
    "label": "European Engineering Learning & Innovation Science Alliance",
    "url": "https://eelisa.eu/what-is-eelisa/"
  },
  {
    "id": 25,
    "label": "The European University engaged in societal change",
    "url": "https://engageuniversity.eu/mission/"
  },
  {
    "id": 26,
    "label": "European University of Technology Alliance",
    "url": "https://enhanceuniversity.eu/about-us/"
  },
  {
    "id": 27,
    "label": " European university Network to promote equitable quality of LIfe, sustainability & Global engagement through Higher education Transformation",
    "url": "https://enlight-eu.org/index.php/university-about-us/our-mission"
  },
  {
    "id": 29,
    "label": "European Reform University Alliance",
    "url": "https://erua-eui.eu/mission/"
  },
  {
    "id": 32,
    "label": "European University for Customised Education",
    "url": "https://eunice-university.eu/"
  },
  {
    "id": 33,
    "label": "The European University for Well-Being",
    "url": "https://www.euniwell.eu/about/vision-and-mission"
  },
  {
    "id": 34,
    "label": "The European University Alliance on Responsible Consumption and Production",
    "url": "https://www.eurecapro.eu/vision-and-mission/"
  },
  {
    "id": 35,
    "label": "Engineering University",
    "url": "https://euroteq.eurotech-universities.eu/about-us/vision-mission/"
  },
  {
    "id": 36,
    "label": "European University of Technology",
    "url": "https://www.univ-tech.eu/mission-statement"
  },
  {
    "id": 38,
    "label": "The European Universities Alliance for Film and Media Arts",
    "url": "https://www.filmeu.eu/mission/"
  },
  {
    "id": 40,
    "label": "Innovations of Regional Sustainability: European University Alliance",
    "url": "https://www.invest-alliance.eu/en/Home/Texts?link=12-mission"
  },
  {
    "id": 41,
    "label": "The European University of Brain and Technology – Neurotech",
    "urL": "https://theneurotech.eu/mission"
  },
  {
    "id": 42,
    "label": "Regional university network - european university",
    "url": "https://run-eu.eu/"
  },
  {
    "id": 44,
    "label": "The European University for Knowledge Entrepreneurs",
    "url": "https://us.edu.pl/european-university/mission/"
  },
  {
    "id": 45,
    "label": "An open to the world, persons-centered and entrepreneurial European University for the citizens of the future",
    "url": "https://ulysseus.eu/"
  },
  {
    "id": 46,
    "label": "European University of PostIndustrial Cities",
    "url": "https://www.unic.eu/en/about/ambitions"
  },
  {
    "id": 47,
    "label": "Universitas montium",
    "url": "https://www.ubi.pt/Sites/unita/en/Pagina/unita"
  },
  {
    "id": 49,
    "label": "European Space University for Earth and Humanity",
    "url": "https://universeh.eu/vision/"
  },
  {
    "id": 12,
    "label": "European University Alliance",
    "url": "https://www.arqus-alliance.eu/about/major-SDGs"
  },
  {
    "id": 11,
    "label": "European University Alliance",
    "url": "https://4euplus.eu/4EU-10.html"
  },
  {
    "id": 15,
    "label": "Charm European University",
    "url": "https://www.charm-eu.eu/mission-vision"
  },
  {
    "id": 17,
    "label": "The European University of Social Sciences",
    "url": "https://www.civica.eu/who-we-are/vision-mission/"
  },
  {
    "id": 18,
    "label": "A European Civic University",
    "url": "https://civis.eu/en/about-civis/our-mission-and-vision"
  },
  {
    "id": 22,
    "label": "THE EUROPEAN CONSORTIUM OF INNOVATIVE UNIVERSITIES",
    "url": "https://www.eciu.org/about-eciu"
  },
  {
    "id": 23,
    "label": "European Digital UniverCity",
    "url": "https://educalliance.eu/"
  },
  {
    "id": 28,
    "label": "The European Partnership for Innovative Campus Unifying Regions",
    "url": "https://epicur.education/our-alliance/mission-statement/"
  },
  {
    "id": 30,
    "label": "Alliance for Common Fine Arts Curriculum",
    "url": "https://eu4art.eu/our-alliance/"
  },
  {
    "id": 19,
    "label": "European University for Smart Urban Coastal Sustainability",
    "url": "https://www.eu-conexus.eu/en/home/about-us/mission-and-vision/"
  },
  {
    "id": 31,
    "label": "European University Alliance for Global Health",
    "url": "https://www.eugloh.eu/about/vision-and-objectives"
  },
  {
    "id": 37,
    "label": "European Universities Transforming to an Open Inclusive Academy for 2050",
    "url": "https://eutopia-university.eu/vision/"
  },
  {
    "id": 39,
    "label": "Fostering Outreach within European Regions, Transnational Higher Education and Mobility",
    "url": "https://www.forthem-alliance.eu/alliance/about-us/"
  },
  {
    "id": 43,
    "label": "European University of the Seas",
    "url": "https://sea-eu.org/who-we-are/"
  },
  {
    "id": 10,
    "label": "Una Europa is an alliance of nine European universities",
    "url": "https://www.una-europa.eu/about/manifesto"
  },
  {
    "id": 48,
    "label": "University Network for Innovation, Technology and Engineering",
    "url": "https://www.unite-university.eu/about-us"
  },
  {
    "id": 50,
    "label": "Young Universities for the Future of Europe",
    "url": "https://yufe.eu/who-we-are"
  }
];
export const AREAS_ARR = [
  { id: "RS01", name: 'Natural Sciences', items: ["RS0101", "RS0102", "RS0103", "RS0104", "RS0105", "RS0106", "RS0107", "RS0108"] },
  { id: "RS02", name: 'Engineering', items: ["RS0201", "RS0202", "RS0203", "RS0204", "RS0205", "RS0206", "RS0207", "RS0208", "RS0209", "RS0210", "RS0211", "RS0212", "RS0213", "RS0214", "RS0215", "RS0216", "RS0217", "RS0218", "RS0219", "RS0220", "RS0221", "RS0222"] },
  { id: "RS03", name: 'Life Sciences', items: ["RS0301", "RS0302", "RS0303", "RS0304"] },
  { id: "RS04", name: 'Medical Sciences', items: ["RS0401", "RS0402", "RS0403", "RS0404", "RS0405", "RS0406"] },
  { id: "RS05", name: 'Social Sciences', items: ["RS0501", "RS0502", "RS0503", "RS0504", "RS0505", "RS0506", "RS0507", "RS0508", "RS0509", "RS0510", "RS0511", "RS0512", "RS0513", "RS0514"] }
]
export const SUBJECTS_RS01 = ["RS0101", "RS0102", "RS0103", "RS0104", "RS0105", "RS0106", "RS0107", "RS0108"];
export const SUBJECTS_RS02 = ["RS0201", "RS0202", "RS0203", "RS0204", "RS0205", "RS0206", "RS0207", "RS0208", "RS0209", "RS0210", "RS0211", "RS0212", "RS0213", "RS0214", "RS0215", "RS0216", "RS0217", "RS0218", "RS0219", "RS0220", "RS0221", "RS0222"];
export const SUBJECTS_RS03 = ["RS0301", "RS0302", "RS0303", "RS0304"];
export const SUBJECTS_RS04 = ["RS0401", "RS0402", "RS0403", "RS0404", "RS0405", "RS0406"];
export const SUBJECTS_RS05 = ["RS0501", "RS0502", "RS0503", "RS0504", "RS0505", "RS0506", "RS0507", "RS0508", "RS0509", "RS0510", "RS0511", "RS0512", "RS0513", "RS0514"];

export const CURRENT_UNIVERSITY_COLOR = "#dd6600";
export const DARK_COLOR = "#241f69";

export const SUBJECTS_ARR = ["RS0101", "RS0201", "RS0202", "RS0203", "RS0204", "RS0205", "RS0206", "RS0207", "RS0301", "RS0401", "RS0501"]

// export const SPARQL_ENDPOINT = 'http://ec2-52-51-177-228.eu-west-1.compute.amazonaws.com/upc/sparql/query'; // http protocol
export const SPARQL_ENDPOINT = 'https://sparql.unics.cloud/upc/sparql/query';

export const RANGE_OPTIONS = {
  STEP: 10,
  MIN: 0,
  MAX: 100
}

export const SORTING = {
  OPTIONS: [
    {
      label: 'name (asc)',
      value: 'name-asc',
      criteria: 'name',
      direction: 'asc'
    },
    {
      label: 'name (desc)',
      value: 'name-desc',
      criteria: 'name',
      direction: 'desc'
    },
    {
      label: 'students (asc)',
      value: 'students-asc',
      criteria: 'totalStudents',
      direction: 'asc'
    },
    {
      label: 'students (desc)',
      value: 'students-desc',
      criteria: 'totalStudents',
      direction: 'desc'
    },
    {
      label: 'staff (asc)',
      value: 'staff-asc',
      criteria: 'totalStaff',
      direction: 'asc'
    },
    {
      label: 'staff (desc)',
      value: 'staff-desc',
      criteria: 'totalStaff',
      direction: 'desc'
    }
  ]
}