import React, { Component } from 'react';
import './index.scss';

import VegaChart from '../../../common/vega/VegaChart'
import { indicatorLineChartSpec } from '../../../common/vega/VegaSpecifications';
import Loader from '../../../common/loader';

import * as _ from 'lodash';
class IndicatorLineChart extends Component {

    constructor() {
        super();
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    shouldComponentUpdate(nextProps, nextState) {

        // console.log("CHOROPLETH MAP");
        // console.log("this.props", this.props);
        // console.log("nextProps", nextProps);

        if (
            _.isEqual(this.props.data, nextProps.data)
        ) {
            return false;
        } else {

            // console.log("MAPDATA - COUNTRIES", nextProps.mapData);

            this.vegaView.signal('dataUpdated', nextProps.data);
            this.vegaView.runAsync();

            return true;
        }
    }

    onVegaViewCreated = vegaView => {

        this.vegaView = vegaView;
        vegaView.signal('dimension', this.props.dimension);
        vegaView.signal('dataUpdated', this.props.data);
        vegaView.runAsync();
    }

    render() {
        const { loading } = this.state;
        return <div className="indicator-line-chart">

                    <VegaChart
                        spec={indicatorLineChartSpec}
                        width={120}
                        height={30}
                        padding={{ "top": 10, "right": 0, "bottom": 0, "left": 0 }}
                        removeDownload={true}
                        onVegaViewCreated={this.onVegaViewCreated}></VegaChart>

            
        </div>
    }
}

export default IndicatorLineChart;