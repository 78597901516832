import React, { Component } from 'react';
// external
import * as _ from 'lodash';
import * as d3 from 'd3';
// styles
import './index.scss';
// components
import VegaChart from '../../common/vega/VegaChart'
import VegaSignalListener from '../../common/vega/VegaSignalListener';
// specs
import { mapSpec } from '../../common/vega/VegaSpecifications';

class ChoroplethMap extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            _.isEqual(this.props.data, nextProps.data) && this.props.currentValue === nextProps.currentValue
        ) {
            return false;
        } else {
            // this.vegaView.signal('onExternalElementHovered', nextProps.country);
            this.vegaView.signal('entity', nextProps.currentValue);
            this.vegaView.signal('dataUpdated', nextProps.data);
            this.vegaView.runAsync();

            return true;
        }
    }


    clearCountry = () => {
        this.props.clearMapValues();
    }

    onVegaViewCreated = vegaView => {

        this.vegaView = vegaView;
        vegaView.signal('entity', this.props.currentValue);
        vegaView.signal('dataUpdated', this.props.data);
        vegaView.runAsync();
    }

    render() {

        // console.log("CHOROPLETH RENDER -> this.props", this.props)

        let onElementClickedListener = new VegaSignalListener('onElementClicked', (name, value, view) => {
            // save selected country to global context
            // console.log("CLICKED VALUE", value)
            this.props.setSelectedCountry(value);
        })

        let onElementHoveredListener = new VegaSignalListener('onElementHovered', (name, value, view) => {
            // save selected country to global context
            // console.log("HOVERED VALUE", value);
            this.props.setHoveredCountry(value);
        })

        const rangeValues = d3.extent(this.props.data, d => d[this.props.currentValue])

        return <div className="choropleth-map">
            <h3 className='title pb-1'>
                European collaborations by country
            </h3>
            <p>
                Distribution of UPC projects in collaboration with other actors.<br />
                Map shows {this.props.currentValue === 'funding' ? 'total financing of the' : 'number of'} collaborations in each country.</p>
            {
                this.props.currentValue === 'funding'
                    ? <p className='mb-0 float-end map-legend'>
                        {d3.format(',.2r')(rangeValues[1])}€
                        <span className='square square-mean mx-2'></span>{d3.format(',.2r')(rangeValues[1] * 0.5)}€
                        <span className='square square-min mx-2'></span>{d3.format(',.2r')(rangeValues[0])}€
                    </p>
                    : <p className='mb-0 float-end map-legend'>
                        {rangeValues[1]} projects
                        <span className='square square-mean mx-2'></span>{Math.round(rangeValues[1] * 0.5)} projects
                        <span className='square square-min mx-2'></span>{rangeValues[0]} project
                    </p>
            }
            <VegaChart
                height={360}
                spec={mapSpec}
                onVegaViewCreated={this.onVegaViewCreated}
                signalListeners={[onElementClickedListener, onElementHoveredListener]}
            />
        </div>
    }
}

export default ChoroplethMap;