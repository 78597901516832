import React, { Component } from 'react';
import './index.scss';
import * as d3 from 'd3';
import { MdArrowBack } from "react-icons/md";
import IndicatorChart from '../../components/indicatorChart';
import BarsInstruments from '../barsIntruments';
import CountryInstitutionsList from '../countryInstitutionsList';


class InstitutionsList extends Component {

    handleCollaborator = (name, country) => this.props.setCollaborator(name, country);

    render() {

        const { data } = this.props;

        return <div className='container'>
            <div className="row">
                <div className="col col-7">
                    <div className="row">
                        <div className="col col-6 py-3">
                            <IndicatorChart
                                config={data.indicators.upcCollaboration}></IndicatorChart>
                        </div>
                        <div className="col col-6 py-3">
                            <IndicatorChart
                                config={data.indicators.upcCountryBudget}></IndicatorChart>
                        </div>
                        <div className="col col-12">
                            <BarsInstruments
                                currentCountry={this.props.currentCountry}
                                data={this.props.countryTableData}></BarsInstruments>
                        </div>

                    </div>
                </div>
                <div className="col col-5">
                    <div className="row">
                        <div className="col col-5 py-3">
                            <IndicatorChart
                                config={data.indicators.upcCountryCollaborators}></IndicatorChart>
                        </div>
                    </div>
                    <div className="row institutions-list">
                        <CountryInstitutionsList
                            {...this.props}></CountryInstitutionsList>
                    </div>

                    {/* 
                    <h3 className="title mb-0">{data['country']} - {d3.format(',')(data['countryStats']['actors'])} actors <span className='float-end'>{d3.format(',')(data['countryStats']['projectsTotal'])} H2020 projects</span></h3>
                    <div className="row indicators-header">
                        <div className="col text-end pb-2 text-muted border-bottom">% country production</div>
                    </div>
                    <div className="container collaborators-scrolling-list">

                        {
                            _.map(data['countryActors'], (actor, index) => <div key={index} className={`item row border-bottom py-2 ${actor['collaborator'] ? 'text-dark' : 'text-muted item-disabled'} ${!actor['collaborator'] && index > 24 ? 'd-none' : ''}`}>
                                
                                <div className="col col-1">
                                    {index + 1}
                                </div>
                                <div className="col col-11">
                                    <div className="row" 
                                        onClick={() => actor['collaborator'] ? this.handleCollaborator(actor['name'], data['country']) : false}
                                         >
                                        <div className="col text-truncate">{actor['name']}</div>
                                        <div className="col col-4 text-end text-truncate">{d3.format(',.2%')(actor['percentage'])} ({d3.format(',')(actor['value'])} {actor['value'] > 1 ? 'H2020 projects' : 'H2020 project'})</div>
                                        {
                                            actor['upcCollaborationProjects'] > 0 &&
                                            <div className="col col-12 text-primary">
                                                <p className='m-0'>
                                                    % UPC: {d3.format(',.2%')(actor['upcPercentageCollaboration'])} ({d3.format(',')(actor['upcCollaborationProjects']) + ' ' + (actor['upcCollaborationProjects'] > 1 ? 'H2020 projects' : 'H2020 project')})
                                                    <span className='float-end'>% collaborator: {d3.format(',.2%')(actor['percentageCollaborationWithUpc'])}</span>
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                */}
                </div>
            </div>
        </div>
    }
}

export default InstitutionsList;