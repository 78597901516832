import React, { Component, Fragment } from 'react';
// external
import * as _ from 'lodash';
import * as d3 from 'd3';
// styles
import './home.scss';
// components
import Loader from '../../common/loader';
import { Provider } from '../../context/Provider';
import { Context } from '../../context/Context';
// import Scrollspy from 'react-scrollspy';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CloseButton from 'react-bootstrap/CloseButton';
import Form from 'react-bootstrap/Form';

// HOME
import IndicatorChart from '../../components/indicatorChart';
import ChoroplethMap from '../../components/choroplethMap';
import FocusCollaborations from '../../components/focusCollaborations';
import FilterRange from '../../components/filterRange';

// COUNTRY
import InstitutionsList from '../../components/institutionsList';
import TreeMapSDG from '../../components/treeMapSDG';
import Wordcloud from '../../components/wordcloud';
import TableProjects from '../../components/tableProjects';
import CountryInstitutionsList from '../../components/countryInstitutionsList';
import BarsActions from '../../components/barsActions';

// INSTITUTION

import FocusCollaborationsInstitution from '../../components/focusCollaborationsInstitution';
import FocusCollaborationsErasmusInstitution from '../../components/focusCollaborationsErasmusInstitution';

import {
    INSTITUTION_NAME
} from '../../common/constants';

class Home extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            showLevel2: false,
            showLevel3: false,
            tabActive: 'eu_projects',
            entityActive: 'value'
        }

    }

    componentDidMount() {

        this.setState({
            loading: false
        })

    }

    setCurrentTab = key => this.setState({ tabActive: key })
    handleEntity = val => this.setState({ entityActive: val.target.value })

    render() {

        const {
            loading
        } = this.state;

        return <div className="home">
            {
                loading
                    ? <Loader text={'Loading alliances data'}></Loader>
                    : <Provider>
                        <Context.Consumer>
                            {
                                context => <Fragment>
                                    {/* {console.log("HOME -> CONTEXT", context)} */}
                                    {/* top navbar */}
                                    <nav className="navbar navbar-primary navbar-title border-bottom p-0 bg-white">
                                        <div className="container py-2">
                                            <a className="navbar-brand" href="#">
                                                <img src="./images/logomark.png" alt="" height={'36'} className='' />
                                            </a>
                                            <h5 className='float-end m-0'>{INSTITUTION_NAME}</h5>
                                        </div>
                                    </nav>
                                    <div className="container py-4 intro-block">
                                        <div className="row pt-4">
                                            <div className="col col-4">
                                                <h2 className='pb-2'>Introduction</h2>
                                                <h4 className='pt-2'>The objective of this dashboard is to showcase UPC’s pattern of collaborations in EU projects with countries and institutions around the world.</h4>
                                                <p className='text-muted p-2 border d-inline-block mt-3'>Note: Private companies are not included in the dashboard.</p>
                                            </div>
                                            <div className="col col-8">
                                                <p className='fs-5'>The dashboard contains data for Horizon 2020 and Erasmus+ projects for the period 2014-2021, which has been obtained from the <a href="https://cordis.europa.eu/" target="blank">CORDIS</a> and <a href='https://erasmus-plus.ec.europa.eu/projects' target="blank">Erasmus+</a> open access databases.</p>
                                                <ul className='fs-5'>
                                                    <li>
                                                        <p className='mb-0'>The dashboard’s three-level structure allows the user to explore the UPC collaborations at global, country and institution level.</p>
                                                    </li>
                                                    <li>
                                                        <p className='mb-0'>The “toggle” feature at the top allows users to switch from H2020 projects to Erasmus+ projects.</p>
                                                    </li>
                                                    <li>
                                                        <p className='mb-0'>When selecting a country (level 2) or an institution (level 3), the list of projects in collaboration with UPC is shown at the bottom of the page - this list can be downloaded in XLS format by clicking on the “Download in XLS format” button.</p>
                                                    </li>
                                                </ul>
                                                {/* <p className='text-muted'>If you encounter an issue or have a request for clarification, please get in contact with xxxxx at xxxx@xxxxx.xxx.”</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container container-years-filter py-4 mt-4">
                                        <div className="row justify-content-center">
                                            <div className="col col-5">
                                                {
                                                    context.loading
                                                        ? <Loader height="300"></Loader>
                                                        : <FilterRange
                                                            config={
                                                                {
                                                                    values: context.yearsRange,
                                                                    step: 1,
                                                                    min: 2014,
                                                                    max: 2021
                                                                }
                                                            }
                                                            onChange={context.setYearsRange}
                                                        ></FilterRange>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container position-relative container-tabs">
                                        <div className="filter-entity-container row py-2">
                                            <div className="col">
                                                <Form onChange={this.handleEntity}>
                                                    <Form.Check
                                                        inline
                                                        label="Number of collaborations"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-radio-1`}
                                                        value={'value'}
                                                        defaultChecked
                                                        // checked={this.state.entityActive === 'value' ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Financing of collaborations"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-radio-2`}
                                                        value={'funding'}
                                                        // checked={this.state.entityActive === 'funding' ? true : false}
                                                    />
                                                </Form>
                                            </div>
                                        </div>
                                        <Tabs
                                            defaultActiveKey="eu_projects"
                                            activeKey={this.state.tabActive}
                                            className="main-tabs"
                                            onSelect={(k) => this.setCurrentTab(k)}
                                            transition={false}>
                                            <Tab eventKey="eu_projects" title="H2020 projects" className='scrolling-tab'>

                                                <div className="container py-4">
                                                    <div className="row">
                                                        <div className="col">
                                                            {
                                                                context.loading
                                                                    ? <Loader height="300"></Loader>
                                                                    : <IndicatorChart
                                                                        config={context.indicators.level1.EUProjectsIndicators.projects}></IndicatorChart>
                                                            }
                                                        </div>
                                                        <div className="col">
                                                            {
                                                                context.loading
                                                                    ? <Loader height="300"></Loader>
                                                                    : <IndicatorChart
                                                                        config={context.indicators.level1.EUProjectsIndicators.budget}></IndicatorChart>
                                                            }
                                                        </div>
                                                        <div className="col">
                                                            {
                                                                context.loading
                                                                    ? <Loader height="300"></Loader>
                                                                    : <IndicatorChart
                                                                        config={context.indicators.level1.EUProjectsIndicators.collaborators}></IndicatorChart>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.tabActive === 'eu_projects'
                                                    && <div className="container mt-4">
                                                        <div className="row">
                                                            <div className="col col-7">
                                                                {
                                                                    context.loading
                                                                        ? <Loader height="300"></Loader>
                                                                        : <ChoroplethMap
                                                                            currentValue={this.state.entityActive}
                                                                            data={context.map.EUProjects.mapData}
                                                                            {...context}></ChoroplethMap>

                                                                }
                                                            </div>

                                                            <div className="col col-5">
                                                                {
                                                                    context.loading
                                                                        ? <Loader height="300"></Loader>
                                                                        :
                                                                        <FocusCollaborations
                                                                            {...context}
                                                                            data={context.collaborationsChart.level1.EUProjects}
                                                                            totalProjectsUPC={context.indicators.level1.EUProjectsIndicators['projects']['rawValue']}
                                                                            totalFundingUPC={context.indicators.level1.EUProjectsIndicators['budget']['rawValue']}
                                                                            currentValue={this.state.entityActive}
                                                                        ></FocusCollaborations>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                }

                                            </Tab>
                                            <Tab eventKey="erasmus_projects" title="Erasmus+ projects" className='scrolling-tab'>
                                                <div className="container container-tabs">
                                                    <div className="row py-4">
                                                        <div className="col">
                                                            {
                                                                context.loading
                                                                    ? <Loader height="300"></Loader>
                                                                    : <IndicatorChart
                                                                        config={context.indicators.level1.erasmusProjectsIndicators.projects}></IndicatorChart>
                                                            }
                                                        </div>
                                                        <div className="col">
                                                            {
                                                                context.loading
                                                                    ? <Loader height="300"></Loader>
                                                                    : <IndicatorChart
                                                                        config={context.indicators.level1.erasmusProjectsIndicators.budget}></IndicatorChart>
                                                            }
                                                        </div>
                                                        <div className="col">
                                                            {
                                                                context.loading
                                                                    ? <Loader height="300"></Loader>
                                                                    : <IndicatorChart
                                                                        config={context.indicators.level1.erasmusProjectsIndicators.collaborators}></IndicatorChart>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.tabActive === 'erasmus_projects'
                                                        && <div className="row mt-4">
                                                            <div className="col col-7">
                                                                {
                                                                    context.loading
                                                                        ? <Loader height="300"></Loader>
                                                                        : <ChoroplethMap
                                                                            currentValue={this.state.entityActive}
                                                                            data={context.map.erasmusProjects.mapData}
                                                                            {...context}></ChoroplethMap>

                                                                }
                                                            </div>
                                                            <div className="col col-5">
                                                                {
                                                                    context.loading
                                                                        ? <Loader height="300"></Loader>
                                                                        :
                                                                        <FocusCollaborations
                                                                            {...context}
                                                                            data={context.collaborationsChart.level1.erasmusProjects}
                                                                            totalProjectsUPC={context.indicators.level1.erasmusProjectsIndicators['projects']['rawValue']}
                                                                            totalFundingUPC={context.indicators.level1.erasmusProjectsIndicators['budget']['rawValue']}
                                                                            currentValue={this.state.entityActive}
                                                                        ></FocusCollaborations>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Tab>

                                        </Tabs>
                                    </div>
                                    {
                                        context.country
                                        && <div id="section-country" className='container-fluid section bg-white'>
                                            <div className="container container-tabs">
                                                {
                                                    context.loading
                                                        ? <Loader height="300"></Loader>
                                                        :
                                                        <div className="row py-4">
                                                            <div className="col">
                                                                <h2>{context.country}</h2>
                                                                <h6>Showing collaborations between {context.yearsRange[0]} and {context.yearsRange[1]}</h6>
                                                            </div>
                                                            <div className="col col-auto ms-auto fs-3">
                                                                <CloseButton onClick={context.resetCountry}></CloseButton>
                                                            </div>
                                                        </div>
                                                }
                                                <Tabs
                                                    defaultActiveKey="eu_projects"
                                                    activeKey={this.state.tabActive}
                                                    className="main-tabs"
                                                    onSelect={(k) => this.setCurrentTab(k)}
                                                    transition={false}>
                                                    <Tab eventKey="eu_projects" title="H2020 projects" className='scrolling-tab'>

                                                        <div className="row">
                                                            {
                                                                this.state.tabActive === 'eu_projects'
                                                                && <div className="col">
                                                                    {
                                                                        context.loading
                                                                            ? <Loader height="300"></Loader>
                                                                            : <InstitutionsList
                                                                                {...context}
                                                                                currentCountry={context.country}
                                                                                data={context.countryRankingData}></InstitutionsList>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="row pt-4">
                                                            {
                                                                this.state.tabActive === 'eu_projects'
                                                                && <div className="col col-6">
                                                                    {
                                                                        context.loading
                                                                            ? <Loader height="300"></Loader>
                                                                            : <TreeMapSDG
                                                                                currentCountry={context.country}
                                                                                data={context.countryTreemapData}></TreeMapSDG>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                this.state.tabActive === 'eu_projects'
                                                                && <div className="col col-6 h-100">
                                                                    {
                                                                        context.loading
                                                                            ? <Loader height="300"></Loader>
                                                                            : <Wordcloud
                                                                                currentCountry={context.country}
                                                                                data={context.countryKeywordsData}
                                                                                size={[600, 400]}></Wordcloud>
                                                                    }
                                                                </div>
                                                            }


                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                {
                                                                    context.loading
                                                                        ? <Loader height="300"></Loader>
                                                                        : <TableProjects
                                                                            currentCountry={context.country}
                                                                            data={context.countryTableData}></TableProjects>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    {
                                                        context.countryErasmusData.length > 0
                                                        && <Tab eventKey="erasmus_projects" title="Erasmus+ projects" className='scrolling-tab'>
                                                            <div className="container-fluid">
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col col-4 py-3">
                                                                            {
                                                                                context.loading
                                                                                    ? <Loader height="300"></Loader>
                                                                                    : <IndicatorChart
                                                                                        config={context.countryErasmusData[0].indicators.projects}></IndicatorChart>
                                                                            }
                                                                        </div>
                                                                        <div className="col col-4 py-3">
                                                                            {
                                                                                context.loading
                                                                                    ? <Loader height="300"></Loader>
                                                                                    : <IndicatorChart
                                                                                        config={context.countryErasmusData[0].indicators.budget}></IndicatorChart>
                                                                            }
                                                                        </div>
                                                                        <div className="col col-2 py-3">
                                                                            {
                                                                                context.loading
                                                                                    ? <Loader height="300"></Loader>
                                                                                    : <IndicatorChart
                                                                                        config={context.countryErasmusData[0].indicators.collaborators}></IndicatorChart>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col col-7">
                                                                            <div className="row">
                                                                                {
                                                                                    this.state.tabActive === 'erasmus_projects'
                                                                                    && <div className="col col-12">
                                                                                        {
                                                                                            context.loading
                                                                                                ? <Loader height="300"></Loader>
                                                                                                : <BarsActions
                                                                                                    currentCountry={context.country}
                                                                                                    data={context.countryErasmusTableData}></BarsActions>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col col-5 institutions-list">
                                                                            {
                                                                                context.loading
                                                                                    ? <Loader height="300"></Loader>
                                                                                    : <CountryInstitutionsList
                                                                                        {...context}
                                                                                        entity={'erasmus'}
                                                                                        data={
                                                                                            {
                                                                                                country: context.country,
                                                                                                countryActors: context.countryErasmusData[0].countryActors,
                                                                                                countryStats: context.countryErasmusData[0].countryStats
                                                                                            }
                                                                                        }></CountryInstitutionsList>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            {
                                                                                context.loading
                                                                                    ? <Loader height="300"></Loader>
                                                                                    : <TableProjects
                                                                                        currentCountry={context.country}
                                                                                        data={context.countryErasmusTableData}></TableProjects>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    }
                                                </Tabs>
                                            </div>
                                        </div>
                                    }
                                    {/* LEVEL 3 */}
                                    {
                                        context.institution
                                        && <div id="section-institution" className='container-fluid section bg-light'>
                                            <div className="container container-tabs">
                                                {
                                                    context.loading
                                                        ? <Loader height="300"></Loader>
                                                        :
                                                        <div className="row py-4">
                                                            <div className="col">
                                                                <h2>{context.institution}</h2>
                                                                <h6>Showing collaborations between {context.yearsRange[0]} and {context.yearsRange[1]}</h6>
                                                            </div>
                                                            <div className="col col-auto ms-auto fs-3">
                                                                <CloseButton onClick={context.resetInstitution}></CloseButton>
                                                            </div>
                                                        </div>
                                                }
                                                <Tabs
                                                    defaultActiveKey="eu_projects"
                                                    activeKey={this.state.tabActive}
                                                    className="main-tabs"
                                                    onSelect={(k) => this.setCurrentTab(k)}
                                                    transition={false}>
                                                    {
                                                        _.some(context.collaborationsChart.level1.EUProjects, ['institution', context.institution])
                                                        && <Tab eventKey="eu_projects" title="H2020 projects" className='scrolling-tab'>
                                                            <div className="container bg-white border-start border-end border-bottom">
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col col-3 py-3">
                                                                            <IndicatorChart
                                                                                config={context.institutionProjectsIndicatorsData.upcCollaboration}></IndicatorChart>
                                                                            <p></p>
                                                                            <IndicatorChart
                                                                                config={context.institutionProjectsIndicatorsData.budget}></IndicatorChart>
                                                                        </div>

                                                                        <div className="col col-9 pt-3">
                                                                            {
                                                                                this.state.tabActive === 'eu_projects'
                                                                                && <FocusCollaborationsInstitution
                                                                                    institution={context.institution}
                                                                                    data={context.institutionCollaborationData}></FocusCollaborationsInstitution>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col col-6">
                                                                            {
                                                                                this.state.tabActive === 'eu_projects'
                                                                                && <TreeMapSDG
                                                                                    currentInstitution={context.institution}
                                                                                    data={context.institutionTreemapData}></TreeMapSDG>
                                                                            }
                                                                        </div>
                                                                        <div className="col col-6 h-100">
                                                                            {
                                                                                this.state.tabActive === 'eu_projects'
                                                                                && <Wordcloud
                                                                                    data={context.institutionKeywordsData}
                                                                                    currentInstitution={context.institution}
                                                                                    size={[600, 400]}></Wordcloud>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            {
                                                                                context.loading
                                                                                    ? <Loader height="300"></Loader>
                                                                                    : <TableProjects
                                                                                        data={context.institutionTableData}
                                                                                        currentInstitution={context.institution}></TableProjects>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    }
                                                    {
                                                        _.some(context.collaborationsChart.level1.erasmusProjects, ['institution', context.institution])
                                                        && <Tab eventKey="erasmus_projects" title="Erasmus+ projects" className='scrolling-tab'>
                                                            <div className="container bg-white border-start border-end border-bottom">
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col col-3 py-3">
                                                                            <IndicatorChart
                                                                                config={context.institutionProjectsIndicatorsData.upcErasmusCollaboration}></IndicatorChart>
                                                                            <p></p>
                                                                            <IndicatorChart
                                                                                config={context.institutionProjectsIndicatorsData.erasmusBudget}></IndicatorChart>
                                                                        </div>
                                                                        <div className="col col-9 py-3">
                                                                            {
                                                                                this.state.tabActive === 'erasmus_projects'
                                                                                && <FocusCollaborationsErasmusInstitution
                                                                                    institution={context.institution}
                                                                                    data={context.institutionErasmusCollaborationData}></FocusCollaborationsErasmusInstitution>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            {
                                                                                context.loading
                                                                                    ? <Loader height="300"></Loader>
                                                                                    : <TableProjects
                                                                                        data={context.institutionErasmusTableData}
                                                                                        currentInstitution={context.institution}></TableProjects>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    }
                                                </Tabs>
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </Context.Consumer>
                    </Provider>
            }
        </div>
    }
}

export default Home;