import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import { history } from './common';
import Home from './sections/Home';

export const RouteApp = props => {
    return (   
        <Router history={history}>
            <Switch>
                <Route exact path={`/`} component={Home} />
            </Switch>
        </Router>
    )        
}