import * as _ from 'lodash';
import * as d3 from 'd3';

import {
    SDG_PALETTE, SDG_TITLES
} from '../common/constants';

export const getProjectsByCountry = (projectsByYear) => _(projectsByYear)
    .groupBy('country')
    .map(function (item) {
        var obj = {};
        obj["label"] = item[0].country;
        obj["value"] = _.sumBy(item, 'projects');
        obj["funding"] = _.sumBy(item, 'funding');
        obj["countryName"] = item[0].country;
        obj["code"] = item[0].alpha3;
        return obj
    }).value();

export const EUProjectsMainIndicator = ({
    dataProjects = [],
    dataCollaboratorsTotal = [],
    dataCollaboratorsByYear = [],
    yearsRange = []
}) => {


    // Filter By Year
    if (yearsRange.length > 0) {
        dataProjects = _.filter(dataProjects, year => year['year'] >= yearsRange[0] && year['year'] <= yearsRange[1])
        dataCollaboratorsByYear = _.filter(dataCollaboratorsByYear, year => year['year'] >= yearsRange[0] && year['year'] <= yearsRange[1])
    }


    const projectsIndicator = {
        label: 'H2020 projects',
        size: 'big',
        units: '',
        value: _.sumBy(dataProjects, 'euProjects'),
        rawValue: _.sumBy(dataProjects, 'euProjects'),
        chart: _.map(dataProjects, o => {
            return { x: o['year'], y: o['euProjects'] }
        })
    }
    const budgetIndicator = {
        label: 'Budget',
        size: 'big',
        units: '€',
        value: d3.format(',.2r')(_.sumBy(dataProjects, 'budget')),
        rawValue: _.sumBy(dataProjects, 'budget'),
        chart: _.map(dataProjects, o => {
            return { x: o['year'], y: o['budget'] }
        })
    }

    const collaboratorsIndicator = {
        label: 'Collaborators',
        size: 'big',
        units: '',
        value: d3.format(',')(dataCollaboratorsTotal[0]['collaborators']),
        rawValue: dataCollaboratorsTotal[0]['collaborators'],
        chart: _.map(dataCollaboratorsByYear, o => {
            return { x: o['year'], y: o['collaborators'] }
        })
    }

    const indicators = {
        projects: projectsIndicator,
        budget: budgetIndicator,
        collaborators: collaboratorsIndicator
    }

    return indicators;
}

export const erasmusProjectsMainIndicator = ({
    dataProjects = [],
    dataCollaboratorsTotal = [],
    dataCollaboratorsByYear = [],
    yearsRange = []
}) => {

    // Filter By Year
    if (yearsRange.length > 0) {
        dataProjects = _.filter(dataProjects, year => year['year'] >= yearsRange[0] && year['year'] <= yearsRange[1])
        dataCollaboratorsByYear = _.filter(dataCollaboratorsByYear, year => year['year'] >= yearsRange[0] && year['year'] <= yearsRange[1])
    }

    const projectsIndicator = {
        label: 'Erasmus+ projects',
        size: 'big',
        units: '',
        value: _.sumBy(dataProjects, 'erasmusplusProjects'),
        rawValue: _.sumBy(dataProjects, 'erasmusplusProjects'),
        chart: _.map(dataProjects, o => {
            return { x: o['year'], y: o['erasmusplusProjects'] }
        })
    }
    const budgetIndicator = {
        label: 'Budget',
        size: 'big',
        units: '€',
        value: d3.format(',.2r')(_.sumBy(dataProjects, 'budget')),
        rawValue: _.sumBy(dataProjects, 'budget'),
        chart: _.map(dataProjects, o => {
            return { x: o['year'], y: o['budget'] }
        })
    }

    const collaboratorsIndicator = {
        label: 'Collaborators',
        size: 'big',
        units: '',
        value: d3.format(',')(dataCollaboratorsTotal[0]['collaborators']),
        rawValue: dataCollaboratorsTotal[0]['collaborators'],
        chart: _.map(dataCollaboratorsByYear, o => {
            return { x: o['year'], y: o['collaborators'] }
        })
    }

    const indicators = {
        projects: projectsIndicator,
        budget: budgetIndicator,
        collaborators: collaboratorsIndicator
    }

    return indicators;
}

export const getEUProjectsCollaborationsInstitutionData = ({
    dataProjects = [],
    dataCollaboratorsProjects = [],
    dataSharedProjects = []
}) => {



    let upcProjectsTotal = _(dataProjects)
        .groupBy('instrument')
        .map(function (item) {
            var obj = {};
            obj["name"] = item[0]['instrument'];
            obj["value"] = _.sumBy(item, 'projectsOfUPC');
            return obj
        }).value();

    let collaboratorProjectsTotal = _(dataCollaboratorsProjects)
        .groupBy('instrument')
        .map(function (item) {
            var obj = {};
            obj["name"] = item[0]['instrument'];
            obj["value"] = _.sumBy(item, 'projectsOfCollaborator');
            return obj
        }).value();

    let sharedProjectsTotal = _(dataSharedProjects)
        .groupBy('instrument')
        .map(function (item) {
            var obj = {};
            obj["name"] = item[0]['instrument'];
            obj["value"] = _.sumBy(item, 'projectsOfUPCWithCollaborator');
            return obj
        }).value();

    let data = [];

    _.map(sharedProjectsTotal, instrument => {

        const foundUPC = _.find(upcProjectsTotal, obj => obj['name'] === instrument['name']);
        const foundCollaborator = _.find(collaboratorProjectsTotal, obj => obj['name'] === instrument['name']);


        data.push({
            instrument: instrument['name'],
            segment: 1,
            projects: foundCollaborator['value'] - instrument['value'],
            totalUPC: foundUPC['value']
        })
        data.push({
            instrument: instrument['name'],
            segment: 0,
            projects: instrument['value'],
            totalUPC: foundUPC['value']
        })
        data.push({
            instrument: instrument['name'],
            segment: 3,
            projects: foundUPC['value'] - instrument['value'],
            totalUPC: foundUPC['value']
        })
    });


    data = _.orderBy(data, ['segment', 'projects'], ['asc', 'desc']);

    return data;
}

export const getErasmusProjectsCollaborationsInstitutionData = ({
    dataProjects = [],
    dataCollaboratorsProjects = [],
    dataSharedProjects = []
}) => {



    let upcProjectsTotal = _(dataProjects)
        .groupBy('action')
        .map(function (item) {
            var obj = {};
            obj["name"] = item[0]['action'];
            obj["value"] = _.sumBy(item, 'projectsOfUPC');
            return obj
        }).value();

    let collaboratorProjectsTotal = _(dataCollaboratorsProjects)
        .groupBy('action')
        .map(function (item) {
            var obj = {};
            obj["name"] = item[0]['action'];
            obj["value"] = _.sumBy(item, 'projectsOfCollaborator');
            return obj
        }).value();

    let sharedProjectsTotal = _(dataSharedProjects)
        .groupBy('action')
        .map(function (item) {
            var obj = {};
            obj["name"] = item[0]['action'];
            obj["value"] = _.sumBy(item, 'projectsOfUPCWithCollaborator');
            return obj
        }).value();

    let data = [];

    _.map(sharedProjectsTotal, action => {

        const foundUPC = _.find(upcProjectsTotal, obj => obj['name'] === action['name']);
        const foundCollaborator = _.find(collaboratorProjectsTotal, obj => obj['name'] === action['name']);


        data.push({
            action: action['name'],
            segment: 1,
            projects: foundCollaborator['value'] - action['value'],
            totalUPC: foundUPC['value']
        })
        data.push({
            action: action['name'],
            segment: 0,
            projects: action['value'],
            totalUPC: foundUPC['value']
        })
        data.push({
            action: action['name'],
            segment: 3,
            projects: foundUPC['value'] - action['value'],
            totalUPC: foundUPC['value']
        })
    });


    data = _.orderBy(data, ['segment', 'projects'], ['asc', 'desc']);

    return data;
}

export const getListProjectsCollaborationsData = ({
    dataProjects = [],
    dataCollaboratorsProjects = [],
    dataSharedProjects = [],
    hoveredCountry = null,
    entity = null
}) => {

    const projectsTotal = {
        name: 'UPC',
        value: _.sumBy(dataProjects, entity),
        funding: _.sumBy(dataProjects, 'budget')
    }

    let collaboratorsProjectsTotal = _(dataCollaboratorsProjects)
        .groupBy('collaborator')
        .map(function (item) {
            var obj = {};
            obj["name"] = item[0].collaborator;
            obj["value"] = _.sumBy(item, 'projectsOfCollaborator');
            obj["countryName"] = item[0].country;
            return obj
        }).value();

    collaboratorsProjectsTotal =
        hoveredCountry !== null
            ? _.filter(collaboratorsProjectsTotal, entry => entry['countryName'] === hoveredCountry)
            : collaboratorsProjectsTotal


    // collaboratorsProjectsTotal = _.orderBy(collaboratorsProjectsTotal, ['value'], ['desc']);

    let sharedProjectsByCollaborator = _(dataSharedProjects)
        .groupBy('collaborator')
        .map(function (item) {
            var obj = {};
            obj["name"] = item[0].collaborator;
            obj["value"] = _.sumBy(item, 'projectsOfUPCWithCollaborator');
            obj["funding"] = _.sumBy(item, 'fundingOfUPC');
            obj["countryName"] = item[0].country;
            return obj
        }).value();

    sharedProjectsByCollaborator =
        hoveredCountry !== null
            ? _.filter(sharedProjectsByCollaborator, entry => entry['countryName'] === hoveredCountry)
            : sharedProjectsByCollaborator


    let data = [];

    _.map(collaboratorsProjectsTotal, collaborator => {

        const found = _.find(sharedProjectsByCollaborator, sharedCollaborator => sharedCollaborator['name'] === collaborator['name']);

        if (found) {

            data.push({
                institution: collaborator['name'],
                segment: 1,
                projects: collaborator['value'] - found['value'],
                funding: found['funding'],
                country: found['countryName']
            })
            data.push({
                institution: collaborator['name'],
                segment: 0,
                projects: found['value'],
                funding: found['funding'],
                country: found['countryName']
            })
            data.push({
                institution: collaborator['name'],
                segment: 3,
                projects: projectsTotal['value'] - found['value'],
                funding: found['funding'],
                country: found['countryName']
            })
        }

    });


    data = _.orderBy(data, ['segment', 'projects'], ['asc', 'desc']);

    return data;
}

export const getCountryRankingData = ({
    country = null,
    countryData = [],
    upcCollaborators = [],
    upcValues = [],
    upcCollaborationsByCountry = [],
    upcTotalCollaborators = null
}) => {

    const upcTotalProjects = _.sumBy(upcValues, 'euProjects');
    const upcTotalBudget = _.sumBy(upcValues, 'budget');
    const upcCountryProjects = upcCollaborationsByCountry[0]['value'];
    const upcCountryBudget = upcCollaborationsByCountry[0]['funding'];
    const countryCollaborationProjects = _.sumBy(upcCollaborators, 'projectsOfUPCWithCollaborator');
    const countryCollaborationBudget = _.sumBy(upcCollaborators, 'fundingOfUPC');
    const collaboratorsList = _.sortedUniq(_.map(upcCollaborators, collaborator => collaborator['collaborator']))

    let countryActors = _(upcCollaborators)
        .groupBy('collaborator')
        .map(function (item) {

            // const found = _.filter(upcCollaborators, obj => obj['collaborator'] === item[0]['organizationName'])

            let obj = {};
            obj["name"] = item[0]['collaborator'];
            obj["value"] = _.sumBy(item, 'projectsOfUPCWithCollaborator');
            obj["percentage"] = _.sumBy(item, 'projectsOfUPCWithCollaborator') / upcTotalProjects;
            obj["upcCollaborationProjects"] = _.sumBy(item, 'projectsOfUPCWithCollaborator');
            obj["upcPercentageCollaboration"] = _.sumBy(item, 'projectsOfUPCWithCollaborator') / upcTotalProjects;
            obj["percentageCollaborationWithUpc"] = _.sumBy(item, 'projectsOfUPCWithCollaborator') / _.sumBy(item, 'projects');
            return obj
        }).value();

    countryActors = _.orderBy(countryActors, ['percentage'], ['desc']);

    const result = {
        country: country,
        countryStats: {
            actors: countryActors.length,
            collaborators: collaboratorsList.length,
            projectsCollaboration: countryCollaborationProjects,
            upcCountryCollaborationsTotal: upcCountryProjects,
            upcCountryCollaborationsPercentage: upcCountryProjects / upcTotalProjects,
        },
        countryActors: countryActors,
        indicators: {
            upcCollaboration: {
                label: '% of UPC projects in collaboration with institutions from ' + country,
                size: 'small',
                units: '',
                value: d3.format(',.1%')(upcCountryProjects / upcTotalProjects),
                secondValue: d3.format(',')(upcCountryProjects) + ' ' + (upcCountryProjects > 1 ? 'H2020 projects' : 'H2020 project')
            },
            upcCountryBudget: {
                label: '% of funding for UPC projects in collaboration with institutions from ' + country,
                size: 'small',
                units: '',
                value: d3.format(',.1%')(upcCountryBudget / upcTotalBudget),
                secondValue: d3.format(',')(upcCountryBudget) + '€'
            },
            upcCountryCollaborators: {
                label: '% of UPC collaborators from ' + country,
                size: 'small',
                units: '',
                value: d3.format(',.1%')(countryActors.length / upcTotalCollaborators),
                secondValue: d3.format(',')(countryActors.length) + ' ' + (countryActors.length > 1 ? 'collaborators' : 'collaborator')
            }
        }
    }

    return result;

}
export const BU_getCountryRankingData = ({
    country = null,
    countryData = [],
    upcCollaborators = [],
    upcValues = [],
    upcCollaborationsByCountry = []
}) => {


    const upcTotalProjects = _.sumBy(upcValues, 'euProjects');
    const upcCountryProjects = upcCollaborationsByCountry[0]['value'];
    const countryTotalProjects = _.sumBy(countryData, 'projects');
    const countryCollaborationProjects = _.sumBy(upcCollaborators, 'projectsOfUPCWithCollaborator');
    const collaboratorsList = _.sortedUniq(_.map(upcCollaborators, collaborator => collaborator['collaborator']))
    const countryActorsList = _.sortedUniq(_.map(countryData, actor => actor['organizationName']))

    let countryActors = _(countryData)
        .groupBy('organizationName')
        .map(function (item) {

            const found = _.filter(upcCollaborators, obj => obj['collaborator'] === item[0]['organizationName'])

            let obj = {};
            obj["name"] = item[0]['organizationName'];
            obj["value"] = _.sumBy(item, 'projects');
            obj["percentage"] = _.sumBy(item, 'projects') / countryTotalProjects;
            obj["collaborator"] = collaboratorsList.includes(item[0]['organizationName']) ? true : false;
            obj["upcCollaborationProjects"] = found ? _.sumBy(found, 'projectsOfUPCWithCollaborator') : 0;
            obj["upcPercentageCollaboration"] = found ? _.sumBy(found, 'projectsOfUPCWithCollaborator') / upcTotalProjects : 0;
            obj["percentageCollaborationWithUpc"] = found ? _.sumBy(found, 'projectsOfUPCWithCollaborator') / _.sumBy(item, 'projects') : 0;
            return obj
        }).value();

    countryActors = _.orderBy(countryActors, ['value'], ['desc']);

    const result = {
        country: country,
        countryStats: {
            actors: countryActorsList.length,
            collaborators: collaboratorsList.length,
            projectsTotal: countryTotalProjects,
            projectsCollaboration: countryCollaborationProjects,
            upcCountryCollaborationsTotal: upcCountryProjects,
            upcCountryCollaborationsPercentage: upcCountryProjects / upcTotalProjects,
        },
        countryActors: countryActors,
        indicators: {
            upcCollaboration: {
                label: '% of collaborative research projects involving institutions from ' + country,
                size: 'small',
                units: '',
                value: d3.format(',.1%')(upcCountryProjects / upcTotalProjects),
                secondValue: d3.format(',')(upcCountryProjects) + ' ' + (upcCountryProjects > 1 ? 'H2020 projects' : 'h2020 project')
            },
            countryCollaborators: {
                label: '% of research institutions from ' + country + ' collaborating with UPC',
                size: 'small',
                units: '',
                value: d3.format(',.1%')(collaboratorsList.length / countryActorsList.length),
                secondValue: d3.format(',')(collaboratorsList.length) + ' ' + (collaboratorsList.length > 1 ? 'institutions' : 'institution')
            }
        }
    }

    return result;

}

export function getCountryErasmusData({
    country = null,
    upcCollaborators = [],
    upcValues = [],
    upcCollaborationsByCountry = [],
    upcTotalCollaborators
}) {

    let result = [];

    if (upcCollaborators.length > 0) {

        const upcTotalProjects = _.sumBy(upcValues, 'erasmusplusProjects');
        const upcTotalBudget = _.sumBy(upcValues, 'budget');
        const upcCountryProjects = upcCollaborationsByCountry[0]['value'];
        const upcCountryBudget = upcCollaborationsByCountry[0]['funding'];
        const countryCollaborationProjects = _.sumBy(upcCollaborators, 'projectsOfUPCWithCollaborator');

        let countryActors = _(upcCollaborators)
            .groupBy('collaborator')
            .map(function (item) {

                // const found = _.filter(upcCollaborators, obj => obj['collaborator'] === item[0]['organizationName'])

                let obj = {};
                obj["name"] = item[0]['collaborator'];
                obj["value"] = _.sumBy(item, 'projectsOfUPCWithCollaborator');
                obj["percentage"] = _.sumBy(item, 'projectsOfUPCWithCollaborator') / upcTotalProjects;
                obj["upcCollaborationProjects"] = _.sumBy(item, 'projectsOfUPCWithCollaborator');
                obj["upcPercentageCollaboration"] = _.sumBy(item, 'projectsOfUPCWithCollaborator') / upcTotalProjects;
                obj["percentageCollaborationWithUpc"] = _.sumBy(item, 'projectsOfUPCWithCollaborator') / _.sumBy(item, 'projects');
                return obj
            }).value();

        countryActors = _.orderBy(countryActors, ['percentage'], ['desc']);

        result.push({
            indicators: {
                projects: {
                    label: '% of UPC projects in collaboration with institutions from ' + country,
                    size: 'small',
                    units: '',
                    value: d3.format(',.1%')(upcCountryProjects / upcTotalProjects),
                    secondValue: d3.format(',')(upcCountryProjects) + ' ' + (upcCountryProjects > 1 ? 'Erasmus+ projects' : 'Erasmus+ project')
                },
                budget: {
                    label: '% of funding for UPC projects in collaboration with institutions from ' + country,
                    size: 'small',
                    units: '',
                    value: d3.format(',.1%')(upcCountryBudget / upcTotalBudget),
                    secondValue: d3.format(',')(upcCountryBudget) + '€'
                },
                collaborators: {
                    label: '% of UPC collaborators from ' + country,
                    size: 'small',
                    units: '',
                    value: d3.format(',.1%')(countryActors.length / upcTotalCollaborators),
                    secondValue: d3.format(',')(countryActors.length) + ' ' + (countryActors.length > 1 ? 'collaborators' : 'collaborator')
                }
            },
            countryActors: countryActors,
            countryStats: {
                actors: countryActors.length,
                projectsCollaboration: countryCollaborationProjects
            }
        });
    }

    return result;

}

export function getCountryTreemapData({
    country = null,
    data = []
}) {

    let countrySDGs = _(data)
        .groupBy('sdgCode')
        .map(function (item) {
            let obj = {};
            obj['parent'] = country;
            obj["name"] = item[0]['sdgCode'];
            obj["color"] = SDG_PALETTE[item[0]['sdgCode']];
            obj["title"] = SDG_TITLES[item[0]['sdgCode']];
            obj["projects"] = _.sumBy(item, 'projectsOfUPCWithCollaboratorCountry');
            return obj
        }).value();

    countrySDGs = _.orderBy(countrySDGs, ['projects'], ['desc']);

    countrySDGs.push({
        name: country,
        parent: null
    });

    return countrySDGs;
}

export function getInstitutionTreemapData({
    collaborator = null,
    country = null,
    data = []
}) {

    let countrySDGs = _(data)
        .groupBy('sdgCode')
        .map(function (item) {
            let obj = {};
            obj['parent'] = country;
            obj["name"] = item[0]['sdgCode'];
            obj["color"] = SDG_PALETTE[item[0]['sdgCode']];
            obj["title"] = SDG_TITLES[item[0]['sdgCode']];
            obj["projects"] = _.sumBy(item, 'projectsOfUPCWithCollaborator');
            return obj
        }).value();

    countrySDGs = _.orderBy(countrySDGs, ['projects'], ['desc']);

    countrySDGs.push({
        name: country,
        parent: null
    });

    return countrySDGs;
}

export function getCountryKeywordsData({
    country = null,
    data = []
}) {

    let countryKeywords = _(data)
        .groupBy('keyword')
        .map(function (item) {
            let obj = {};
            obj["text"] = item[0]['keyword'];
            obj["value"] = _.sumBy(item, 'projectsOfUPCWithCollaboratorCountry');
            return obj
        }).value();

    countryKeywords = _.orderBy(countryKeywords, ['value'], ['desc']);
    countryKeywords = _.slice(countryKeywords, [0], [50]);

    return countryKeywords;
}

export function getInstitutionKeywordsData({
    collaborator = null,
    country = null,
    data = []
}) {

    let institutionKeywords = _(data)
        .groupBy('keyword')
        .map(function (item) {
            let obj = {};
            obj["text"] = item[0]['keyword'];
            obj["value"] = _.sumBy(item, 'projectsOfUPCWithCollaborator');
            return obj
        }).value();

    institutionKeywords = _.orderBy(institutionKeywords, ['value'], ['desc']);
    institutionKeywords = _.slice(institutionKeywords, [0], [50]);

    return institutionKeywords;
}

export function getProjectsTableData({
    country = null,
    dataProjects = [],
    dataCollaborators = [],
    dataProgrammes = [],
    dataInstruments = [],
    dataSDG = [],
    dataKeywords = [],
    dataActions = [],
}) {


    const enrichDataProjects = _.map(dataProjects, project => {

        const collaborators = _.filter(dataCollaborators, projectCollaborator => projectCollaborator['projId'] === project['projId']);

        const instrument = _.find(dataInstruments, projectInstrument => projectInstrument['projId'] === project['projId']);
        const programme = _.find(dataProgrammes, projectProgramme => projectProgramme['projId'] === project['projId']);
        const action = _.find(dataActions, projectAction => projectAction['projId'] === project['projId']);
        const SDG = _.filter(dataSDG, projectSDG => projectSDG['projId'] === project['projId']);
        const keywords = _.filter(dataKeywords, projectKeywords => projectKeywords['projId'] === project['projId']);

        project['collaborators'] = collaborators;
        project['instrument'] = instrument ? instrument['instrument'] : null;
        project['action'] = action ? action['action'] : null;
        project['programme'] = programme ? programme['programme'] : null;
        project['SDG'] = SDG ? SDG : '--';
        project['keywords'] = keywords ? keywords : '--';
        return project;
    })

    return enrichDataProjects;
}

export function getCountryProjectsByInstrumentData({
    dataProjects = [],
    dataInstruments = []
}) {
    const instrumentDataProjects = _.map(dataProjects, project => {

        const instrument = _.find(dataInstruments, projectInstrument => projectInstrument['projId'] === project['projId']);

        project['instrument'] = instrument['instrument'];
        project['count'] = 1;
        return project;
    })

    return instrumentDataProjects;
}

export function getCountryErasmusByActionData({
    dataProjects = [],
    dataActions = []
}) {
    const actionDataProjects = _.map(dataProjects, project => {

        const action = _.find(dataActions, projectAction => projectAction['projId'] === project['projId']);

        project['action'] = action['action'];
        project['count'] = 1;
        return project;
    })

    return actionDataProjects;
}

export function getInstitutionIndicatorsData({
    institution = null,
    upcInstitutionProjects = [],
    upcTotalProjects = null,
    upcTotalBudget = null,
    upcErasmusTotalProjects = null,
    upcErasmusTotalBudget = null,
    upcInstitutionErasmusProjects = []
}) {

    const collaborationsTotalBudget = _.sumBy(upcInstitutionProjects, 'fundingOfUPC')
    const collaborationsErasmusTotalBudget = _.sumBy(upcInstitutionErasmusProjects, 'fundingOfUPC')

    let institutionIndicators = {};

    institutionIndicators['upcCollaboration'] = {
        label: '% of UPC projects in collaboration with ' + institution,
        size: 'small',
        units: '',
        value: d3.format(',.1%')(upcInstitutionProjects.length / upcTotalProjects),
        secondValue: d3.format(',')(upcInstitutionProjects.length) + ' ' + (upcInstitutionProjects.length > 1 ? 'H2020 projects' : 'H2020 project')
    }
    institutionIndicators['budget'] = {
        label: '% of funding for UPC projects in collaboration with ' + institution,
        size: 'small',
        units: '',
        value: d3.format(',.1%')(collaborationsTotalBudget / upcTotalBudget),
        secondValue: d3.format(',')(collaborationsTotalBudget) + '€'
    }
    institutionIndicators['upcErasmusCollaboration'] = {
        label: '% of UPC projects in collaboration with ' + institution,
        size: 'small',
        units: '',
        value: d3.format(',.1%')(upcInstitutionErasmusProjects.length / upcErasmusTotalProjects),
        secondValue: d3.format(',')(upcInstitutionErasmusProjects.length) + ' ' + (upcInstitutionErasmusProjects.length > 1 ? 'Erasmus+ projects' : 'Erasmus+ project')
    }
    institutionIndicators['erasmusBudget'] = {
        label: '% of funding for UPC projects in collaboration with ' + institution,
        size: 'small',
        units: '',
        value: d3.format(',.1%')(collaborationsErasmusTotalBudget / upcErasmusTotalBudget),
        secondValue: d3.format(',')(collaborationsErasmusTotalBudget) + '€'
    }

    return institutionIndicators;
}