import React, { Component } from 'react';
import './index.scss';

import VegaChart from '../../common/vega/VegaChart'
import VegaSignalListener from '../../common/vega/VegaSignalListener';
import { barsActionsSpec } from '../../common/vega/VegaSpecifications';
import * as _ from 'lodash';

class BarsActions extends Component {

    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            _.isEqual(this.props.data, nextProps.data)
        ) {
            return false;
        } else {
            return true;
        }
    }

    onVegaViewCreated = vegaView => {
        this.vegaView = vegaView;
        vegaView.signal('dataTotal', this.props.data.length);
        vegaView.signal('dataUpdated', this.props.data);
        vegaView.runAsync();
    }

    render() {

        
        return <div className="bars-chart">
            <h3 className='title pb-1'>Distribution by action</h3>
            <p>Distribution of UPC projects in collaboration with {this.props.currentCountry} by action.<br/>Graph shows number and percentage of projects.</p>
            <div className="">
                <VegaChart
                    spec={barsActionsSpec}
                    padding={{ "top": 0, "right": 0, "bottom": 0, "left": 0 }}
                    onVegaViewCreated={this.onVegaViewCreated}
                ></VegaChart>
            </div>


        </div>
    }
}

export default BarsActions;