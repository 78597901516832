export const treeMapSDGSpec = {
  "$schema": "https://vega.github.io/schema/vega/v5.json",
  "description": "Treemap showing country or institution projects distribution by SDGs",
  "padding": { "top": 0, "left": 0, "right": 0, "bottom": 0 },
  "signals": [
    {
      "name": "clickedAlliance",
      "on": [
        {
          "events": "@rect_nodes:click",
          "update": "datum"
        }
      ]
    },
    {
      "name": "selectedAllianceName",
      "value": ""
    },
    { "name": "dataUpdated", "value": [] }
  ],
  "data": [
    {
      "name": "table",
      "values": { "signal": "dataUpdated" },
      "transform": [
        { "type": "stratify", "key": "name", "parentKey": "parent" },
        {
          "type": "treemap",
          "field": "projects",
          "round": true,
          "method": "squarify",
          "size": [{ "signal": "width" }, { "signal": "height" }]
        }
      ]
    },
    {
      "name": "nodes",
      "source": "table",
      "transform": [
        { "type": "filter", "expr": "datum.children && datum.depth === 1" }
      ]
    },
    {
      "name": "leaves",
      "source": "table",
      "transform": [{ "type": "filter", "expr": "!datum.children" }]
    }
  ],
  // "legends": [{"type": "symbol", "fill": "color"}],
  "scales": [
    {
      "name": "color",
      "type": "ordinal",
      "domain": { "data": "nodes", "field": "name" },
      "range": [
        "#3182bd",
        "#6baed6",
        "#9ecae1",
        "#c6dbef",
        "#e6550d",
        "#fd8d3c",
        "#fdae6b",
        "#fdd0a2",
        "#31a354",
        "#74c476",
        "#a1d99b",
        "#c7e9c0",
        "#756bb1",
        "#9e9ac8",
        "#bcbddc",
        "#dadaeb",
        "#636363",
        "#969696",
        "#bdbdbd",
        "#d9d9d9"
      ]
    },
    {
      "name": "size",
      "type": "ordinal",
      "domain": [0, 1, 2, 3],
      "range": [256, 28, 20, 14]
    }
  ],
  "marks": [
    {
      "type": "rect",
      "name": "rect_nodes",
      "from": { "data": "nodes" },
      "interactive": false,
      "encode": {
        "enter": {
          "stroke": { "value": "rgba(255,255,255,1)" },
          "strokeWidth": { "value": 2 }
        },
        "update": {
          // "fill": {"scale": "color", "field": "name"},
          "fill": { "value": "transparent" },
          "x": { "field": "x0" },
          "y": { "field": "y0" },
          "x2": { "field": "x1" },
          "y2": { "field": "y1" },
          "opacity": { "signal": "selectedAllianceName === datum.name ? 1 : 0.85" }
          // "tooltip": {
          //   "signal": "{title: datum.name, Staff: format(datum.totalStaff, ','), Students: format(datum.totalStudents, ','), EUProjects: format(datum.totalEUProjects, ',')}"
          // }    
        },
        "hover": {
          "opacity": { "value": 1 }
        }
      }
    },
    {
      "type": "rect",
      "from": { "data": "leaves" },
      "interactive": true,
      "encode": {
        "enter": { "stroke": { "value": "rgba(255,255,255,1)" } },
        "update": {
          "x": { "field": "x0" },
          "y": { "field": "y0" },
          "x2": { "field": "x1" },
          "y2": { "field": "y1" },
          "fill": { "field": "color" },
          "opacity": { "value": 0.85 },
          "tooltip": {
            "signal": "{title: datum.name, name: datum['title'], 'H2020 projects': format(datum['projects'], ',')}"
          }
        },
        "hover": {
          "opacity": { "value": 1 }
        }
      }
    },
    {
      "type": "text",
      "from": { "data": "leaves" },
      "interactive": false,
      "encode": {
        "enter": {
          "align": { "value": "center" },
          "baseline": { "value": "middle" },
          "fill": { "value": "#FFF" },
          "text": { "field": "name" },
          "fontSize": { "signal": "(datum.x1 - datum.x0) * 0.25" },
          "fontWeight": { "value": 700 },
          "fillOpacity": { "value": 0.6 }
        },
        "update": {
          "x": { "signal": "0.5 * (datum.x0 + datum.x1)" },
          "y": { "signal": "0.5 * (datum.y0 + datum.y1)" }
        }
      }
    }
  ]
};