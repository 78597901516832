import React, { Component, Fragment } from 'react';

import './index.scss';

import ReactWordcloud from 'react-wordcloud';

class Wordcloud extends Component {

    shouldComponentUpdate(nextProps, nextState) {

        if (
            _.isEqual(this.props.data, nextProps.data)
        ) {
            return false;
        } else {
            return true;
        }
    }


    render() {

        // const callbacks = {
        //     onWordClick: word => this.props.setSelectedKeyword(word.text)
        //   }

        // const wcWords = this.props.currentAllianceID
        // ? this.props.keywordsByAlliance[this.props.currentAllianceID]
        // : this.props.formatedAllKeywords
       
        const options = {
            rotations: 0,
            rotationAngles: [0, 0],
            colors: '#CC0000',
            fontFamily: 'Barlow',
            fontSizes: [10, 36]
        };

        return <Fragment>
            <div className="row">
                <div className="col">
                    <h3 className='title pb-1'>Collaboration wordcloud</h3>
                    <p>Keywords from the UPC projects in collaboration with {this.props.currentCountry ? this.props.currentCountry : this.props.currentInstitution}. Size of keyword represents the number of projects.</p>
                </div>
            </div>
            <div className="row">
                <div className="col wordcloud">
                    {/* {
                        _.map(this.props.data, (kwd, index) => <span key={index} className='badge badge-primary'>
                            {kwd['text']} - {kwd['value']}
                        </span>)
                    } */}
                    <div className="w-100 h-100">
                        <ReactWordcloud
                            words={this.props.data}
                            options={options}
                            // callbacks={callbacks} 
                            />
                    </div>

                    {/* <VegaChart
                        signalListeners={[
                            new VegaSignalListener('clickedKeyword', (name, value, view) => {
                                this.props.setSelectedKeyword(value);
                            })
                        ]}
                        spec={wordCloudSpec}
                        height={400}
                        onVegaViewCreated={this.onVegaViewCreated}>
                    </VegaChart> */}

                </div>
            </div>
        </Fragment>;
    }
}

export default Wordcloud;