import React, { Component } from 'react';
import './index.scss';

import VegaChart from '../../common/vega/VegaChart'
import VegaSignalListener from '../../common/vega/VegaSignalListener';
import { focusCollaborationsErasmusInstitutionSpec } from '../../common/vega/VegaSpecifications';
import * as _ from 'lodash';

import {
    INSTITUTION_NAME
} from '../../common/constants'

class FocusCollaborationsErasmusInstitution extends Component {

    constructor() {
        super();
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (
            _.isEqual(this.props.data, nextProps.data)
        ) {
            return false;
        } else {

            this.vegaView.signal('dataUpdated', []);
            this.vegaView.runAsync();
            this.vegaView.signal('dataUpdated', nextProps.data);
            this.vegaView.runAsync();

            return true;
        }
    }

    onVegaViewCreated = vegaView => {

        this.vegaView = vegaView;
        vegaView.signal('upc_total', this.props.data.length);
        vegaView.signal('dataUpdated', this.props.data);
        vegaView.runAsync();
    }

    render() {

        return <div className="container institution-collaboration-chart bg-light">
            <div className="row">
                <div className="col">
                    <h3 className="title pb-1">Distribution by action</h3>
                    <p>Distribution of UPC and {this.props.institution} projects, by action. Graph represents the total share of projects by each of the two institutions as well as the share of shared projects. Percentage number represents the percentage of shared projects over the total of UPC projects, in the corresponding action.</p>
                </div>
            </div>
            <div className="row pb-2 pt-4">
                <div className="col">
                    <span className='square square-collaborator me-2'></span>{this.props.institution}
                </div>
                <div className="col text-center">
                    <span className='square square-shared mx-2'></span>Shared projects
                </div>
                <div className="col text-end">
                    <span className='ms-auto'>{INSTITUTION_NAME}</span><span className='square square-upc ms-2'></span>
                </div>
                <div className="scrolling-chart">
                    <VegaChart
                        spec={focusCollaborationsErasmusInstitutionSpec}
                        padding={{ "top": 0, "right": 0, "bottom": 40, "left": 0 }}
                        onVegaViewCreated={this.onVegaViewCreated}
                    ></VegaChart>
                </div>
            </div>


        </div>
    }
}

export default FocusCollaborationsErasmusInstitution;