import React, { Component } from 'react';
import { Range, getTrackBackground } from 'react-range';
import * as d3 from 'd3';
import {
    DARK_COLOR
} from "../../common/constants"


class FilterRange extends Component {

    render() {

        const { entity, config } = this.props;

        return <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap'
            }}
        >
            <Range
                values={config.values}
                step={config.step}
                min={config.min}
                max={config.max}
                onChange={(value) => this.props.onChange({type: entity, filter: value})}
                renderMark={({ props, index }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: '8px',
                        width: '2px',
                        backgroundColor: index * config.step < config.values[0] || index * config.step > config.values[1] ? DARK_COLOR : '#ccc'
                      }}
                    />
                  )}
                renderTrack={({ props, children }) => (
                    <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                            ...props.style,
                            height: '18px',
                            display: 'flex',
                            width: '100%'
                        }}
                    >
                        <div
                            ref={props.ref}
                            style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '4px',
                                background: getTrackBackground({
                                    values: config.values,
                                    colors: ['#ccc', DARK_COLOR, '#ccc'],
                                    min: config.min,
                                    max: config.max
                                }),
                                alignSelf: 'center'
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ index, props, isDragged }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '21px',
                            width: '21px',
                            borderRadius: '21px',
                            backgroundColor: '#FFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0px 2px 6px #AAA'
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '-24px',
                                color: DARK_COLOR,
                                fontWeight: 'bold',
                                fontSize: '12px'
                            }}
                        >
                            {config.values[index]}
                        </div>
                        <div
                            style={{
                                height: '8px',
                                width: '5px',
                                backgroundColor: isDragged ? DARK_COLOR : '#CCC'
                            }}
                        />
                    </div>
                )}
            />
            {/* <output style={{ marginTop: '0px' }}><small>{config.values[0]} - {config.values[1]}</small></output> */}
        </div>;
    }
}

export default FilterRange;