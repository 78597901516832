import _ from 'lodash';
import * as d3 from 'd3';
import React, { Component } from 'react';
import { Context } from './Context';

import {
    getEUProjectsByYear,
    getEUCollaboratorsTotal,
    getEUCollaboratorsByYear,
    getEUCountriesMap,
    getCollaboratorsEUProjectsByYear,
    getSharedEUProjectsByYear,
    getCountryEUProjects,
    getCountrySDG,
    getCountryKeywords,
    getCountryProjectsTable,
    getCountryProjectCollaboratorsTable,
    getCountryProjectProgrammesTable,
    getCountryProjectInstrumentsTable,
    getCountryProjectSDGTable,
    getCountryProjectKeywordsTable,
    getInstitutionProjectsByInstrumentYear,
    getCollaboratorProjectsByInstrumentYear,
    getCollaborationProjectsByInstrumentYear,
    getInstitutionSDG,
    getInstitutionKeywords,
    getInstitutionProjectsTable,
    getInstitutionProjectCollaboratorsTable,
    getInstitutionProjectProgrammesTable,
    getInstitutionProjectInstrumentsTable,
    getInstitutionProjectSDGTable,
    getInstitutionProjectKeywordsTable,
    getErasmusProjectsByYear,
    getErasmusCollaboratorsTotal,
    getErasmusCollaboratorsByYear,
    getErasmusCountriesMap,
    getCollaboratorsErasmusProjectsByYear,
    getSharedErasmusProjectsByYear,
    getCountryErasmusTable,
    getCountryErasmusCollaboratorsTable,
    getCountryErasmusActionsTable,
    getInstitutionErasmusByActionYear,
    getCollaboratorErasmusByActionYear,
    getCollaborationErasmusByActionYear,
    getInstitutionErasmusTable,
    getInstitutionErasmusCollaboratorsTable,
    getInstitutionErasmusActionsTable
} from '../data/DataService';

import {
    EUProjectsMainIndicator,
    getProjectsByCountry,
    getListProjectsCollaborationsData,
    getCountryRankingData,
    getCountryTreemapData,
    getCountryKeywordsData,
    getProjectsTableData,
    getCountryProjectsByInstrumentData,
    getEUProjectsCollaborationsInstitutionData,
    getInstitutionTreemapData,
    getInstitutionKeywordsData,
    erasmusProjectsMainIndicator,
    getInstitutionIndicatorsData,
    getCountryErasmusData,
    getCountryErasmusByActionData,
    getErasmusProjectsCollaborationsInstitutionData
} from './analytics'

export class Provider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            country: undefined,
            filters: [],
            fullYearsRange: [2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
            yearsRange: [2014, 2021]
        };

        this.setSelectedCountry = this.setSelectedCountry.bind(this);
        this.setHoveredCountry = this.setHoveredCountry.bind(this);
        this.setCollaborator = this.setCollaborator.bind(this);
        this.resetCountry = this.resetCountry.bind(this);
        this.resetInstitution = this.resetInstitution.bind(this);
        this.setYearsRange = this.setYearsRange.bind(this);
        this.getFilters = this.getFilters.bind(this);
    }



    ///////////////////////////////////////////////////////////////////////////
    ////////// FETCHING DATA //////////////////////////////////////////////////



    ///////////////////////////////////////////////////////////////////////////
    ////////// GETTERS ////////////////////////////////////////////////////////


    // HOME
    async setSelectedCountry(country) {

        d3.select("#vg-tooltip-element").remove();
        d3.selectAll(".home").classed('inactive', true);

        this.setState({
            loading: true
        })

        let currentFilters = { ...this.state.filters };
        // Remove any value with country
        currentFilters = _.filter(currentFilters, filter => filter.type !== 'country');
        currentFilters = [...currentFilters, { type: 'country', value: country, label: country }]

        // LIST AND INDICATORS
        let countryEUProjects = await getCountryEUProjects(country);
        let upcEUCollaborators = _.filter(this.state.storage.sharedEUProjectsByYear, collaborator => collaborator['country'] === country);
        let upcEUValues = this.state.storage.EUProjectsByYear;
        // filter by year
        countryEUProjects = _.filter(countryEUProjects, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]);
        upcEUCollaborators = _.filter(upcEUCollaborators, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]);
        upcEUValues = _.filter(upcEUValues, year => year.year >= this.state.yearsRange[0] && year.year <= this.state.yearsRange[1])

        const countryEURankingData = getCountryRankingData({
            country: country,
            countryData: countryEUProjects,
            upcCollaborators: upcEUCollaborators,
            upcValues: upcEUValues,
            upcCollaborationsByCountry: _.filter(this.state.map['EUProjects'].mapData, collaborator => collaborator['countryName'] === country),
            upcTotalCollaborators: this.state.indicators.level1.EUProjectsIndicators.collaborators.rawValue
        })

        // SDG TREEMAP
        let countryDistributionSDG = await getCountrySDG(country);
        // filter by years
        countryDistributionSDG = _.filter(countryDistributionSDG, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]);
        const countryTreemapData = getCountryTreemapData({
            country: country,
            data: countryDistributionSDG
        })

        // KEYWORDS WORDCLOUD
        let countryKeywords = await getCountryKeywords(country);
        // filter by years
        countryKeywords = _.filter(countryKeywords, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]);
        const countryKeywordsData = getCountryKeywordsData({
            country: country,
            data: countryKeywords
        })

        // PROJECTS TABLE
        let countryProjectsTable = await getCountryProjectsTable(country);
        countryProjectsTable = _.filter(countryProjectsTable, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]);
        const countryProjectCollaboratorsTable = await getCountryProjectCollaboratorsTable(country);
        const countryProjectProgrammesTable = await getCountryProjectProgrammesTable(country);
        const countryProjectInstrumentsTable = await getCountryProjectInstrumentsTable(country);
        const countryProjectSDGTable = await getCountryProjectSDGTable(country);
        const countryProjectKeywordsTable = await getCountryProjectKeywordsTable(country);
        const countryProjectsTableData = getProjectsTableData({
            country: country,
            dataProjects: countryProjectsTable,
            dataCollaborators: countryProjectCollaboratorsTable,
            dataProgrammes: countryProjectProgrammesTable,
            dataInstruments: countryProjectInstrumentsTable,
            dataSDG: countryProjectSDGTable,
            dataKeywords: countryProjectKeywordsTable
        })

        // PROJECTS BY INSTRUMENT

        let countryProjectsByInstrumentData = getCountryProjectsByInstrumentData({
            dataProjects: countryProjectsTable,
            dataInstruments: countryProjectInstrumentsTable
        });

        countryProjectsByInstrumentData = _.filter(countryProjectsByInstrumentData, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]);

        // Indicators for erasmus+
        // DATA
        let upcErasmusCollaborators = _.filter(this.state.storage.sharedErasmusProjectsByYear, collaborator => collaborator['country'] === country);
        let upcErasmusValues = this.state.storage.erasmusProjectsByYear;
        // filter by year
        upcErasmusCollaborators = _.filter(upcErasmusCollaborators, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]);
        upcErasmusValues = _.filter(upcErasmusValues, year => year.year >= this.state.yearsRange[0] && year.year <= this.state.yearsRange[1])

        const countryErasmusData = getCountryErasmusData({
            country: country,
            upcCollaborators: upcErasmusCollaborators,
            upcValues: upcErasmusValues,
            upcCollaborationsByCountry: _.filter(this.state.map['erasmusProjects'].mapData, collaborator => collaborator['countryName'] === country),
            upcTotalCollaborators: this.state.indicators.level1.erasmusProjectsIndicators.collaborators.rawValue
        })

        let countryErasmusTable = await getCountryErasmusTable(country);
        countryErasmusTable = _.filter(countryErasmusTable, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]);
        const countryErasmusCollaboratorsTable = await getCountryErasmusCollaboratorsTable(country);
        const countryErasmusActionsTable = await getCountryErasmusActionsTable(country);
        const countryErasmusTableData = getProjectsTableData({
            country: country,
            dataProjects: countryErasmusTable,
            dataCollaborators: countryErasmusCollaboratorsTable,
            dataActions: countryErasmusActionsTable
        })


        // ERASMUS BY ACTION

        let countryErasmusByActionData = getCountryErasmusByActionData({
            dataProjects: countryErasmusTable,
            dataActions: countryErasmusActionsTable
        });

        countryErasmusByActionData = _.filter(countryErasmusByActionData, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]);

        this.setState({
            ...this.state,
            country,
            countryName: country,
            countryRankingData: countryEURankingData,
            countryTreemapData: countryTreemapData,
            countryKeywordsData: countryKeywordsData,
            countryTableData: countryProjectsTableData,
            countryProjectsByInstrumentData: countryProjectsByInstrumentData,
            loading: false,
            countryErasmusData: countryErasmusData,
            countryErasmusTableData: countryErasmusTableData,
            countryErasmusByActionData: countryErasmusByActionData
        })
    }

    setHoveredCountry(country) {

        let currentCollaborationsChart = { ...this.state.collaborationsChart };

        // Filter by years
        // 1. EU projects
        const EUProjectsByYear = _.filter(this.state.storage.EUProjectsByYear, year => year.year >= this.state.yearsRange[0] && year.year <= this.state.yearsRange[1])
        const collaboratorsEUProjectsByYear = _.filter(this.state.storage.collaboratorsEUProjectsByYear, collaboration => collaboration.year >= this.state.yearsRange[0] && collaboration.year <= this.state.yearsRange[1])
        const sharedEUProjectsByYear = _.filter(this.state.storage.sharedEUProjectsByYear, collaboration => collaboration.year >= this.state.yearsRange[0] && collaboration.year <= this.state.yearsRange[1])
        // 2. erasmus projects
        const erasmusProjectsByYear = _.filter(this.state.storage.erasmusProjectsByYear, year => year.year >= this.state.yearsRange[0] && year.year <= this.state.yearsRange[1])
        const collaboratorsErasmusProjectsByYear = _.filter(this.state.storage.collaboratorsErasmusProjectsByYear, collaboration => collaboration.year >= this.state.yearsRange[0] && collaboration.year <= this.state.yearsRange[1])
        const sharedErasmusProjectsByYear = _.filter(this.state.storage.sharedErasmusProjectsByYear, collaboration => collaboration.year >= this.state.yearsRange[0] && collaboration.year <= this.state.yearsRange[1])

        const EUProjectsCollaborationsData = getListProjectsCollaborationsData(
            {
                dataProjects: EUProjectsByYear,
                dataCollaboratorsProjects: collaboratorsEUProjectsByYear,
                dataSharedProjects: sharedEUProjectsByYear,
                hoveredCountry: country,
                entity: 'euProjects'
            }
        )
        const erasmusProjectsCollaborationsData = getListProjectsCollaborationsData(
            {
                dataProjects: erasmusProjectsByYear,
                dataCollaboratorsProjects: collaboratorsErasmusProjectsByYear,
                dataSharedProjects: sharedErasmusProjectsByYear,
                hoveredCountry: country,
                entity: 'erasmusplusProjects'
            }
        )

        currentCollaborationsChart.level1.EUProjects = EUProjectsCollaborationsData;
        currentCollaborationsChart.level1.erasmusProjects = erasmusProjectsCollaborationsData;

        this.setState({
            hoveredCountry: country,
            collaborationsChart: currentCollaborationsChart
        })

    }

    async setCollaborator(collaborator, country) {

        d3.selectAll(".home").classed('inactive', true);

        this.setState({
            loading: true
        })
        // LOAD DATA
        // 1. EU projects
        const upcEUProjects = await getInstitutionProjectsByInstrumentYear();
        const collaboratorEUProjects = await getCollaboratorProjectsByInstrumentYear(collaborator, country);
        const sharedEUProjects = await getCollaborationProjectsByInstrumentYear(collaborator, country);
        // 2. erasmus+ projects
        const upcErasmusProjects = await getInstitutionErasmusByActionYear();
        const collaboratorErasmusProjects = await getCollaboratorErasmusByActionYear(collaborator, country);
        const sharedErasmusProjects = await getCollaborationErasmusByActionYear(collaborator, country);

        // COLLABORATIONS CHART
        // 1. EU projects
        const EUProjectsCollaborationsData = getEUProjectsCollaborationsInstitutionData(
            {
                dataProjects: _.filter(upcEUProjects, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]),
                dataCollaboratorsProjects: _.filter(collaboratorEUProjects, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]),
                dataSharedProjects: _.filter(sharedEUProjects, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1])
            }
        )

        // 2. erasmus projects
        const erasmusProjectsCollaborationsData = getErasmusProjectsCollaborationsInstitutionData(
            {
                dataProjects: _.filter(upcErasmusProjects, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]),
                dataCollaboratorsProjects: _.filter(collaboratorErasmusProjects, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]),
                dataSharedProjects: _.filter(sharedErasmusProjects, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1])
            }
        )

        // SDG TREEMAP
        const institutionDistributionSDG = await getInstitutionSDG(collaborator, country);
        const institutionTreemapData = getInstitutionTreemapData({
            collaborator: collaborator,
            country: country,
            data: _.filter(institutionDistributionSDG, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1])
        });


        // KEYWORDS WORDCLOUD
        const institutionKeywords = await getInstitutionKeywords(collaborator, country);
        const institutionKeywordsData = getInstitutionKeywordsData({
            collaborator: collaborator,
            country: country,
            data: _.filter(institutionKeywords, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1])
        })

        // PROJECTS TABLE
        // 1. EU projects
        const institutionProjectsTable = await getInstitutionProjectsTable(collaborator, country);
        const institutionProjectCollaboratorsTable = await getInstitutionProjectCollaboratorsTable(collaborator, country);
        const institutionProjectProgrammesTable = await getInstitutionProjectProgrammesTable(collaborator, country);
        const institutionProjectInstrumentsTable = await getInstitutionProjectInstrumentsTable(collaborator, country);
        const institutionProjectSDGTable = await getInstitutionProjectSDGTable(collaborator, country);
        const institutionProjectKeywordsTable = await getInstitutionProjectKeywordsTable(collaborator, country);
        const institutionProjectsTableData = getProjectsTableData({
            country: country,
            dataProjects: _.filter(institutionProjectsTable, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]),
            dataCollaborators: institutionProjectCollaboratorsTable,
            dataProgrammes: institutionProjectProgrammesTable,
            dataInstruments: institutionProjectInstrumentsTable,
            dataSDG: institutionProjectSDGTable,
            dataKeywords: institutionProjectKeywordsTable
        })

        // 2. erasmus projects
        const institutionErasmusTable = await getInstitutionErasmusTable(collaborator, country);
        const institutionErasmusCollaboratorsTable = await getInstitutionErasmusCollaboratorsTable(collaborator, country);
        const institutionErasmusActionsTable = await getInstitutionErasmusActionsTable(collaborator, country);

        const institutionErasmusTableData = getProjectsTableData({
            country: country,
            dataProjects: _.filter(institutionErasmusTable, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]),
            dataCollaborators: institutionErasmusCollaboratorsTable,
            dataActions: institutionErasmusActionsTable
        })

        // TOP INDICATORS
        const institutionProjectsIndicatorsData = getInstitutionIndicatorsData({
            institution: collaborator,
            upcTotalProjects: this.state.indicators.level1.EUProjectsIndicators.projects.rawValue,
            upcTotalBudget: this.state.indicators.level1.EUProjectsIndicators.budget.rawValue,
            upcInstitutionProjects: _.filter(institutionProjectsTable, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]),
            upcErasmusTotalProjects: this.state.indicators.level1.erasmusProjectsIndicators.projects.rawValue,
            upcErasmusTotalBudget: this.state.indicators.level1.erasmusProjectsIndicators.budget.rawValue,
            upcInstitutionErasmusProjects: _.filter(institutionErasmusTable, year => year['year'] >= this.state.yearsRange[0] && year['year'] <= this.state.yearsRange[1]),
        })

        this.setState({
            institution: collaborator,
            institutionCollaborationData: EUProjectsCollaborationsData,
            institutionErasmusCollaborationData: erasmusProjectsCollaborationsData,
            institutionTreemapData: institutionTreemapData,
            institutionKeywordsData: institutionKeywordsData,
            institutionTableData: institutionProjectsTableData,
            institutionProjectsIndicatorsData: institutionProjectsIndicatorsData,
            institutionErasmusTableData: institutionErasmusTableData,
            loading: false
        });
        return true;
    }

    getFilters() {
        return this.state.filters;
    }

    resetCountry() {
        d3.selectAll(".home").classed('inactive', false);
        this.setState({
            country: null,
            countryName: null
        })
    }
    resetInstitution() {

        !this.state.country && d3.selectAll(".home").classed('inactive', false);

        this.setState({
            institution: null
        })
    }

    async setYearsRange(obj) {

        let currentIndicators = { ...this.state.indicators };
        let currentCollaborationsChart = { ...this.state.collaborationsChart };

        const excludedyears = this.state.fullYearsRange.filter(year => year < obj['filter'][0] || year > obj['filter'][1]);

        // LEVEL 1

        // 1. EU indicators
        const EUCollaboratorsTotal = await getEUCollaboratorsTotal(excludedyears);
        const EUProjectsIndicators = EUProjectsMainIndicator({
            dataProjects: this.state.storage.EUProjectsByYear,
            dataCollaboratorsTotal: EUCollaboratorsTotal,
            dataCollaboratorsByYear: this.state.storage.EUCollaboratorsByYear,
            yearsRange: obj['filter']
        });

        currentIndicators.level1.EUProjectsIndicators = EUProjectsIndicators;


        // 2. erasmus+ indicators
        const erasmusCollaboratorsTotal = await getErasmusCollaboratorsTotal(excludedyears);
        const erasmusProjectsIndicators = erasmusProjectsMainIndicator({
            dataProjects: this.state.storage.erasmusProjectsByYear,
            dataCollaboratorsTotal: erasmusCollaboratorsTotal,
            dataCollaboratorsByYear: this.state.storage.erasmusCollaboratorsByYear,
            yearsRange: obj['filter']
        });
        currentIndicators.level1.erasmusProjectsIndicators = erasmusProjectsIndicators;

        // Map information filtered by year

        let currentMap = { ...this.state.map };

        const EUMapData = _.filter(this.state.storage.EUCountriesMap, collaboration => collaboration.year >= obj['filter'][0] && collaboration.year <= obj['filter'][1])
        const EUProjectsMapData = getProjectsByCountry(EUMapData);
        const erasmusMapData = _.filter(this.state.storage.erasmusCountriesMap, collaboration => collaboration.year >= obj['filter'][0] && collaboration.year <= obj['filter'][1])
        const erasmusProjectsMapData = getProjectsByCountry(erasmusMapData);

        currentMap.EUProjects.mapData = EUProjectsMapData;
        currentMap.erasmusProjects.mapData = erasmusProjectsMapData;

        // Collaborations chart filtered by year
        // 1. EU projects
        const EUProjectsByYear = _.filter(this.state.storage.EUProjectsByYear, year => year.year >= obj['filter'][0] && year.year <= obj['filter'][1])
        const collaboratorsEUProjectsByYear = _.filter(this.state.storage.collaboratorsEUProjectsByYear, collaboration => collaboration.year >= obj['filter'][0] && collaboration.year <= obj['filter'][1])
        const sharedEUProjectsByYear = _.filter(this.state.storage.sharedEUProjectsByYear, collaboration => collaboration.year >= obj['filter'][0] && collaboration.year <= obj['filter'][1])
        const EUProjectsCollaborationsData = getListProjectsCollaborationsData(
            {
                dataProjects: EUProjectsByYear,
                dataCollaboratorsProjects: collaboratorsEUProjectsByYear,
                dataSharedProjects: sharedEUProjectsByYear,
                entity: 'euProjects'
            }
        )
        // 2. Erasmus projects
        const erasmusProjectsByYear = _.filter(this.state.storage.erasmusProjectsByYear, year => year.year >= obj['filter'][0] && year.year <= obj['filter'][1])
        const collaboratorsErasmusProjectsByYear = _.filter(this.state.storage.collaboratorsErasmusProjectsByYear, collaboration => collaboration.year >= obj['filter'][0] && collaboration.year <= obj['filter'][1])
        const sharedErasmusProjectsByYear = _.filter(this.state.storage.sharedErasmusProjectsByYear, collaboration => collaboration.year >= obj['filter'][0] && collaboration.year <= obj['filter'][1])
        const erasmusProjectsCollaborationsData = getListProjectsCollaborationsData(
            {
                dataProjects: erasmusProjectsByYear,
                dataCollaboratorsProjects: collaboratorsErasmusProjectsByYear,
                dataSharedProjects: sharedErasmusProjectsByYear,
                entity: 'erasmusplusProjects'
            }
        )
        currentCollaborationsChart.level1.EUProjects = EUProjectsCollaborationsData;
        currentCollaborationsChart.level1.erasmusProjects = erasmusProjectsCollaborationsData;

        this.setState({
            yearsRange: obj['filter'],
            indicators: currentIndicators,
            map: currentMap,
            collaborationsChart: currentCollaborationsChart
        })


        return true;
    }

    ///////////////////////////////////////////////////////////////////////////
    ////////// UTILS  ////////////////////////////////////////////////////////

    // COMPLETE ALLIANCES INFORMATION

    async componentDidMount() {


        const excludedyears = this.state.fullYearsRange.filter(year => year < this.state.yearsRange[0] || year > this.state.yearsRange[1])

        // INDICATORS HOME

        // 1. EU Projects
        const EUProjectsByYear = await getEUProjectsByYear();
        // remove years out of range
        const EUCollaboratorsTotal = await getEUCollaboratorsTotal(excludedyears);
        const EUCollaboratorsByYear = await getEUCollaboratorsByYear();

        const EUProjectsIndicators = EUProjectsMainIndicator({
            dataProjects: EUProjectsByYear,
            dataCollaboratorsTotal: EUCollaboratorsTotal,
            dataCollaboratorsByYear: EUCollaboratorsByYear,
            yearsRange: this.state.yearsRange
        });

        // 2. erasmus+ projects
        const erasmusProjectsByYear = await getErasmusProjectsByYear();
        const erasmusCollaboratorsTotal = await getErasmusCollaboratorsTotal(excludedyears);
        const erasmusCollaboratorsByYear = await getErasmusCollaboratorsByYear();

        const erasmusProjectsIndicators = erasmusProjectsMainIndicator({
            dataProjects: erasmusProjectsByYear,
            dataCollaboratorsTotal: erasmusCollaboratorsTotal,
            dataCollaboratorsByYear: erasmusCollaboratorsByYear,
            yearsRange: this.state.yearsRange
        });

        // MAP
        // 1. EU Projects
        let EUCountriesMap = await getEUCountriesMap();
        EUCountriesMap = _.filter(EUCountriesMap, collaboration => collaboration.year >= this.state.yearsRange[0] && collaboration.year <= this.state.yearsRange[1])
        const EUProjectsMapData = getProjectsByCountry(EUCountriesMap);
        // 1. Erasmus Projects
        let erasmusCountriesMap = await getErasmusCountriesMap();
        erasmusCountriesMap = _.filter(erasmusCountriesMap, collaboration => collaboration.year >= this.state.yearsRange[0] && collaboration.year <= this.state.yearsRange[1])
        const erasmusProjectsMapData = getProjectsByCountry(erasmusCountriesMap);



        // COLLABORATIONS CHART
        // 1. EU Projects
        const collaboratorsEUProjectsByYear = await getCollaboratorsEUProjectsByYear();
        const sharedEUProjectsByYear = await getSharedEUProjectsByYear();

        const EUProjectsCollaborationsData = getListProjectsCollaborationsData(
            {
                dataProjects: EUProjectsByYear,
                dataCollaboratorsProjects: collaboratorsEUProjectsByYear,
                dataSharedProjects: sharedEUProjectsByYear,
                entity: 'euProjects'
            }
        )

        // 1. Erasmus Projects

        const collaboratorsErasmusProjectsByYear = await getCollaboratorsErasmusProjectsByYear();
        const sharedErasmusProjectsByYear = await getSharedErasmusProjectsByYear();

        const erasmusProjectsCollaborationsData = getListProjectsCollaborationsData(
            {
                dataProjects: erasmusProjectsByYear,
                dataCollaboratorsProjects: collaboratorsErasmusProjectsByYear,
                dataSharedProjects: sharedErasmusProjectsByYear,
                entity: 'erasmusplusProjects'
            }
        )

        this.setState({
            loading: false,
            storage: {
                EUCountriesMap: EUCountriesMap,
                EUProjectsMapData: EUProjectsMapData,
                EUProjectsByYear: EUProjectsByYear,
                EUCollaboratorsTotal: EUCollaboratorsTotal,
                EUCollaboratorsByYear: EUCollaboratorsByYear,
                collaboratorsEUProjectsByYear: collaboratorsEUProjectsByYear,
                sharedEUProjectsByYear: sharedEUProjectsByYear,
                erasmusCountriesMap: erasmusCountriesMap,
                erasmusCollaboratorsTotal: erasmusCollaboratorsTotal,
                erasmusCollaboratorsByYear: erasmusCollaboratorsByYear,
                erasmusProjectsMapData: erasmusProjectsMapData,
                erasmusProjectsByYear: erasmusProjectsByYear,
                collaboratorsErasmusProjectsByYear: collaboratorsErasmusProjectsByYear,
                sharedErasmusProjectsByYear: sharedErasmusProjectsByYear
            },
            map: {
                EUProjects: {
                    initialMapData: EUProjectsMapData,
                    mapData: EUProjectsMapData
                },
                erasmusProjects: {
                    initialMapData: erasmusProjectsMapData,
                    mapData: erasmusProjectsMapData,
                }
            },
            indicators: {
                level1: {
                    EUProjectsIndicators: EUProjectsIndicators,
                    erasmusProjectsIndicators: erasmusProjectsIndicators
                },
                level2: []
            },
            collaborationsChart: {
                level1: {
                    EUProjects: EUProjectsCollaborationsData,
                    erasmusProjects: erasmusProjectsCollaborationsData
                }
            }
        });

    }

    render() {
        return (
            <Context.Provider value={{
                setSelectedCountry: this.setSelectedCountry,
                setHoveredCountry: this.setHoveredCountry,
                setCollaborator: this.setCollaborator,
                resetCountry: this.resetCountry,
                resetInstitution: this.resetInstitution,
                setYearsRange: this.setYearsRange,
                getFilters: this.getFilters,
                ...this.state
            }}>
                {this.props.children}
            </Context.Provider>
        );
    }
}