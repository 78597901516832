import * as _ from 'lodash';
import * as d3 from 'd3';
import {
    SPARQL_ENDPOINT
} from '../common/constants';

import { queries } from '../common/queries/index';

import query from '../common/sparql/index';

// UPC
export async function getEUProjectsByYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.EUProjectsByYear
    );

    return result;
}

export async function getEUCollaboratorsTotal(array) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.EUProjectsCollaboratorsTotal(array)
    );

    return result;
}

export async function getEUCollaboratorsByYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.EUProjectsCollaboratorsByYear
    );

    return result;
}

export async function getEUCountriesMap() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.EUCountriesMap
    );

    return result;
}

// Collaborations chart
export async function getCollaboratorsEUProjectsByYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.collaboratorsEUProjectsByYear
    );

    return result;
}

export async function getSharedEUProjectsByYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.sharedEUProjectsByYear
    );

    return result;
}

// Country level

export async function getCountryEUProjects(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryEUProjects(country)
    );

    return result;
}

export async function getCountrySDG(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countrySDG(country)
    );

    return result;
}

export async function getCountryKeywords(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryKeywords(country)
    );

    return result;
}

export async function getCountryProjectsTable(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryProjectsTable(country)
    );

    return result;
}

export async function getCountryProjectCollaboratorsTable(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryProjectCollaboratorsTable(country)
    );

    return result;
}

export async function getCountryProjectProgrammesTable(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryProjectProgrammesTable(country)
    );

    return result;
}

export async function getCountryProjectInstrumentsTable(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryProjectInstrumentsTable(country)
    );

    return result;
}

export async function getCountryProjectSDGTable(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryProjectSDGTable(country)
    );

    return result;
}

export async function getCountryProjectKeywordsTable(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryProjectKeywordsTable(country)
    );

    return result;
}

// LEVEL 3 INSTITUTION
export async function getInstitutionProjectsByInstrumentYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionProjectsByInstrumentYear
    );

    return result;
}
export async function getInstitutionErasmusByActionYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionErasmusByActionYear
    );
    return result;
}
export async function getCollaboratorProjectsByInstrumentYear(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.collaboratorProjectsByInstrumentYear(collaborator, country)
    );
    return result;
}

export async function getCollaboratorErasmusByActionYear(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.collaboratorErasmusByActionYear(collaborator, country)
    );

    return result;
}

export async function getCollaborationProjectsByInstrumentYear(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.collaborationProjectsByInstrumentYear(collaborator, country)
    );
    return result;
}

export async function getCollaborationErasmusByActionYear(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.collaborationErasmusByActionYear(collaborator, country)
    );
    return result;
}

// SDGS
export async function getInstitutionSDG(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionSDG(collaborator, country)
    );

    return result;
}
// KEYWORDS
export async function getInstitutionKeywords(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionKeywords(collaborator, country)
    );

    return result;
}

// TABLE

export async function getInstitutionProjectsTable(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionProjectsTable(collaborator, country)
    );

    return result;
}

export async function getInstitutionProjectCollaboratorsTable(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionProjectCollaboratorsTable(collaborator, country)
    );

    return result;
}

export async function getInstitutionProjectProgrammesTable(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionProjectProgrammesTable(collaborator, country)
    );

    return result;
}

export async function getInstitutionProjectInstrumentsTable(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionProjectInstrumentsTable(collaborator, country)
    );

    return result;
}

export async function getInstitutionProjectSDGTable(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionProjectSDGTable(collaborator, country)
    );

    return result;
}

export async function getInstitutionProjectKeywordsTable(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionProjectKeywordsTable(collaborator, country)
    );

    return result;
}

// ERASMUS

export async function getErasmusProjectsByYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.erasmusProjectsByYear
    );
    
    return result;
}

export async function getErasmusCollaboratorsTotal(array) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.erasmusProjectsCollaboratorsTotal(array)
    );

    return result;
}

export async function getErasmusCollaboratorsByYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.erasmusProjectsCollaboratorsByYear
    );

    return result;
}

export async function getErasmusCountriesMap() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.erasmusCountriesMap
    );

    return result;
}

// Collaborations chart
export async function getCollaboratorsErasmusProjectsByYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.collaboratorsErasmusProjectsByYear
    );

    return result;
}

export async function getSharedErasmusProjectsByYear() {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.sharedErasmusProjectsByYear
    );

    return result;
}

// TABLE -> LEVEL COUNTRY

export async function getCountryErasmusTable(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryErasmusTable(country)
    );

    return result;
}

export async function getCountryErasmusCollaboratorsTable(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryErasmusCollaboratorsTable(country)
    );

    return result;
}

export async function getCountryErasmusActionsTable(country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.countryErasmusActionsTable(country)
    );

    return result;
}

// TABLE LEVEL INSTITUTION
export async function getInstitutionErasmusTable(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionErasmusTable(collaborator, country)
    );

    return result;
}

export async function getInstitutionErasmusCollaboratorsTable(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionErasmusCollaboratorsTable(collaborator, country)
    );

    return result;
}

export async function getInstitutionErasmusActionsTable(collaborator, country) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.institutionErasmusActionsTable(collaborator, country)
    );

    return result;
}
