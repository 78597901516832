import React, { Component, Fragment } from 'react';
// import * as _ from 'lodash';
import VegaChart from '../../common/vega/VegaChart'
import {
    treeMapSDGSpec
} from '../../common/vega/VegaSpecifications';
import VegaSignalListener from '../../common/vega/VegaSignalListener';
import './index.scss';

class TreeMapSDG extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            data: []
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (_.isEqual(this.props.data, nextProps.data)) {
            return false;
        } else {

            this.vegaView.signal('dataUpdated', nextProps.data);
            this.vegaView.runAsync();
            return true;
        }
    }


    onVegaViewCreated = vegaView => {

        this.vegaView = vegaView;
        vegaView.signal('dataUpdated', this.props.data);
        vegaView.runAsync();
    }

    render() {

        return <Fragment>
            <div className="row">
                <div className="col">
                    <h3 className='title pb-1'>Collaboration by SDG</h3>
                    <p>Share of UPC projects in collaboration with {this.props.currentCountry ? this.props.currentCountry : this.props.currentInstitution} by Sustainable Development Goals. Size of SDG areas corresponds to the number of projects. Not all projects are classifiable into SDGs. A project can be classified into multiple SDGs.</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <VegaChart
                        spec={treeMapSDGSpec}
                        height={300}
                        padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        onVegaViewCreated={this.onVegaViewCreated}>
                    </VegaChart>
                </div>
            </div>
        </Fragment>
    }
}

export default TreeMapSDG;