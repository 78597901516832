import React, { Component } from 'react';
import './index.scss';

import VegaChart from '../../common/vega/VegaChart'
import VegaSignalListener from '../../common/vega/VegaSignalListener';
import { barsInstrumentsSpec } from '../../common/vega/VegaSpecifications';
import * as _ from 'lodash';

class BarsInstruments extends Component {

    constructor() {
        super();
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    shouldComponentUpdate(nextProps, nextState) {

        // console.log("CHOROPLETH MAP");
        // console.log("this.props", this.props);
        // console.log("nextProps", nextProps);

        if (
            _.isEqual(this.props.data, nextProps.data)
        ) {
            return false;
        } else {

            // console.log("Collaborations", nextProps.data);

            // const sortData = _.orderBy(nextProps.data, ['segment', 'projects'], ['asc', 'desc']);

            // this.vegaView.signal('onExternalElementHovered', nextProps.country);
            // this.vegaView.signal('dataUpdated', []);
            // this.vegaView.runAsync();
            // this.vegaView.signal('dataUpdated', nextProps.data);
            // this.vegaView.runAsync();

            return true;
        }
    }

    onVegaViewCreated = vegaView => {
        this.vegaView = vegaView;
        vegaView.signal('dataTotal', this.props.data.length);
        vegaView.signal('dataUpdated', this.props.data);
        vegaView.runAsync();
    }

    render() {

        // console.log("FOCUS COLLABORATION BARS -> this.props", this.props)
        
        return <div className="bars-chart">
            <h3 className='title pb-1'>Distribution by instrument</h3>
            <p>Distribution of UPC projects in collaboration with {this.props.currentCountry} by instrument.<br/>Graph shows number and percentage of projects.</p>
            <div className="">
                <VegaChart
                    spec={barsInstrumentsSpec}
                    padding={{ "top": 0, "right": 0, "bottom": 0, "left": 0 }}
                    onVegaViewCreated={this.onVegaViewCreated}
                ></VegaChart>
            </div>


        </div>
    }
}

export default BarsInstruments;