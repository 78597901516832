export const indicatorLineChartSpec = {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "signals":[
      {
        "name": "dimension",
        "description": "Label tooltip",
        "value": null
      },
      { "name": "dataUpdated", "value": [] }
    ],
    "data": [
      {
        "name": "table",
        "values": { "signal": "dataUpdated" },
        "transform": [
          {
            "type": "formula",
            "expr": "+datum.x",
            "as": "x"
          },
          {
            "type": "formula",
            "expr": "+datum.y",
            "as": "y"
          }
        ]
      },
      {
        "name": "labels_data",
        "source": "table",
        "transform": [
          {"type": "collect", "sort": {"field": "x"}},
          {"type": "extent", "field": "x", "signal": "extent"},
          {
            "type": "filter",
            "expr": "datum.x == extent[0] || datum.x == extent[1] && datum.x"
          }
        ]
      }
    ],
    "scales": [
      {
        "name": "x",
        "type": "point",
        "range": "width",
        "domain": {"data": "table", "field": "x"}
      },
      {
        "name": "y",
        "type": "linear",
        "range": "height",
        "nice": true,
        "zero": true,
        "domain": {"data": "table", "field": "y"}
      }
    ],
    "marks": [
      {
        "type": "line",
        "from": {"data": "table"},
        "encode": {
          "update": {
            "x": {"scale": "x", "field": "x"},
            "y": {"scale": "y", "field": "y"},
            "strokeWidth": {"value": 2},
            "stroke": {
              "signal": "span(pluck(data('table'), 'y')) > 0? '#097AC2':'#097AC2'"
            }
          },
          "hover": {"strokeOpacity": {"value": 0.5}}
        }
      },
      {
        "type": "symbol",
        "shape": "circle",
        "from": {"data": "labels_data"},
        "encode": {
          "update": {
            "size": {"value": 48},
            "x": {"scale": "x", "field": "x"},
            "y": {"scale": "y", "field": "y"},
            "strokeWidth": {"value": 12},
            "fill": {
              "signal": "span(pluck(data('table'), 'y')) > 0? '#097AC2':'#097AC2'"
            },
            "strokeOpacity": {"value": 1},
            "tooltip": {"signal": "{title: datum.x, '': format(datum.y, ',')}"}
          },
          "hover": {"strokeOpacity": {"value": 0.5}}
        }
      },
      {
        "type": "text",
        "from": {"data": "labels_data"},
        "encode": {
          "update": {
            "text": {"field": "x"},
            "x": {"scale": "x", "field": "x"},
            "y": {"scale": "y", "field": "y"},
            "dx": {"signal": "datum.x == extent[1] ? 5 : -5"},
            "dy": {"value": 16},
            "align": {"signal": "datum.x == extent[1] ? 'right' : 'left'"},
            "strokeOpacity": {"value": 1}
          },
          "hover": {"strokeOpacity": {"value": 0.5}}
        }
      }
    ]
  };