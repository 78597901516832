import { bindingsToJsMap } from './ResultSet';

export default async function query(endpoint, queryString) {


    // data from the SPARQL result
    let result = [];

    // Prepare the HTTP request via POST with URL-encoded parameters
    // https://www.w3.org/TR/sparql11-protocol/#query-via-post-urlencoded
    // The SPARQL protocol accepts also queries via GET or via POST directly

    const postQuery = async function(url = '', query) {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: { 
                // must set this content type when querying via POST
                'Content-Type': 'application/x-www-form-urlencoded',
                // get sure we get a JSON instead default XML
                'Accept': 'application/sparql-results+json'
            },
            body: 'query=' + query
        });

        // fetch won’t reject on HTTP error status even if the response
        // is an HTTP 404 or 500. Instead, it will resolve normally with
        // ok status set to false
        if(response.ok)
            return await response.json();
        else {
            // capture the error message
            const err = await response.json();            
            throw Error(
                (err.error + ': ' + err.message).replace(/(\r\n|\n|\r)/gm, "")
            );
        }
    };

    try {
        const queryResults = await postQuery(endpoint, queryString);
        result = bindingsToJsMap(queryResults);        
    } catch (error) {
        console.log(error);
    }

    return result;
}