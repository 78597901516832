export const focusCollaborationsErasmusInstitutionSpec = {

  "$schema": "https://vega.github.io/schema/vega/v5.json",
  "style": "cell",
  "signals": [
    { "name": "y_step", "value": 17 },
    {
      "name": "height",
      "update": "bandspace(domain('y').length, 0.1, 0.05) * y_step"
    },
    { "name": "upc_total", "value": 0 },
    { "name": "dataUpdated", "value": [] }
  ],
  "data": [
    {
      "name": "source_0",
      "values": { "signal": "dataUpdated" },
      "format": { "type": "json" },
      "transform": [
        {
          "type": "formula",
          "expr": "datum.segment == 1 ? 'Collaborator' : (datum.segment == 0 ? 'Shared' : 'UPCProjects')",
          "as": "type"
        },
        {
          "type": "aggregate",
          "groupby": ["action", "type", "totalUPC"],
          "ops": ["sum"],
          "fields": ["projects"],
          "as": ["sum_projects"]
        },
        {
          "type": "stack",
          "groupby": ["action"],
          "field": "sum_projects",
          "sort": { "field": ["type"], "order": ["ascending"] },
          "as": ["sum_projects_start", "sum_projects_end"],
          "offset": "normalize"
        }
      ]
    },
    {
      "name": "data_0",
      "source": "source_0",
      "transform": [
        {
          "type": "filter",
          "expr": "isValid(datum[\"sum_projects\"]) && isFinite(+datum[\"sum_projects\"])"
        }
      ]
    },
    {
      "name": "data_1",
      "source": "source_0",
      "transform": [
        {
          "type": "filter",
          "expr": "isValid(datum[\"sum_projects\"]) && isFinite(+datum[\"sum_projects\"])"
        }
      ]
    }
  ],
  "marks": [
    {
      "name": "layer_0_marks",
      "type": "rect",
      "style": ["bar"],
      "from": { "data": "data_1" },
      "encode": {
        "update": {
          "fill": { "scale": "color", "field": "type" },
          "ariaRoleDescription": { "value": "bar" },
          "x": { "scale": "x", "field": "sum_projects_end" },
          "x2": { "scale": "x", "field": "sum_projects_start" },
          "y": { "scale": "y", "field": "action" },
          "height": { "scale": "y", "band": 1 },
          "tooltip": {
            "signal": "{title: datum.type, EUprojects : datum.sum_projects}"
          }
        }
      }
    },
    {
      "name": "layer_1_marks",
      "type": "text",
      "style": ["text"],
      "from": { "data": "data_0" },
      "interactive": false,
      "encode": {
        "update": {
          "opacity": { "value": 0.9 },
          "fill": { "value": "white" },
          "x": {
            "signal": "scale(\"x\", datum[\"sum_projects_start\"])"
          },
          "y": { "scale": "y", "field": "action", "band": 0.5 },
          "dx": {"value": 3},
          // "text": { "signal": "datum['type'] == 'Shared' ? format(datum[\"sum_projects\"] / upc_total, ',.1%') + ' ' + format(datum[\"sum_projects\"], \"\") + ' projects' : ''" },
          "text": { "signal": "datum['type'] == 'Shared' ? format(datum[\"sum_projects\"] / datum['totalUPC'], ',.1%') : ''" },
          "align": { "value": "left" },
          "baseline": { "value": "middle" }
        }
      }
    }
  ],
  "scales": [
    {
      "name": "x",
      "type": "linear",
      "domain": [0, 1],
      "range": [0, { "signal": "width" }],
      "nice": true,
      "zero": true,
    },
    // {
    //   "name": "x_steps",
    //   "type": "linear",
    //   "domain": {"data": "data_1", "field": "sum_projects"},
    //   "range": "width"
    // },
    {
      "name": "y",
      "type": "band",
      "domain": {
        "fields": [
          { "data": "data_1", "field": "action" },
          { "data": "data_0", "field": "action" }
        ],
        "sort": false
      },
      "range": { "step": { "signal": "y_step" } },
      "paddingInner": 0.1,
      "paddingOuter": 0.05
    },
    {
      "name": "color",
      "type": "ordinal",
      "domain": { "data": "data_1", "field": "type", "sort": true },
      "range": ["#D2D2D2", "#7694A7", "#7CB9E0"]
    }
  ],
  "axes": [
    { "scale": "y", "orient": "left", "grid": false, "title": "", "zindex": 0, "labelLimit": 300 },
    { "scale": "y", "orient": "right", "grid": false, "title": "", "zindex": 0, "labelLimit": 300 },
    // {
    //   "scale": "x_steps",
    //   "orient": "top",
    //   "grid": true,
    //   "gridColor": "#FFF",
    //   "gridOpacity": 0.5,
    //   "zindex": 1,
    //   "bandPosition": 0,
    //   "domain": false,
    //   "ticks": false,
    //   "tickSize": {"signal": "rangeStep"},
    //   "tickOffset": 0,
    //   "labels": false,
    //   "values": [
    //     0,
    //     1,
    //     2,
    //     3,
    //     4,
    //     5,
    //     6,
    //     7,
    //     8,
    //     9,
    //     10,
    //     11,
    //     12,
    //     13,
    //     14,
    //     15,
    //     16,
    //     17,
    //     18,
    //     19,
    //     20,
    //     21,
    //     22,
    //     23,
    //     24,
    //     25,
    //     26,
    //     27,
    //     28,
    //     29,
    //     30,
    //     31,
    //     32,
    //     33,
    //     34,
    //     35,
    //     36,
    //     37,
    //     38,
    //     39,
    //     40,
    //     41,
    //     42,
    //     43,
    //     44,
    //     45,
    //     46,
    //     47,
    //     48,
    //     49,
    //     50,
    //     51,
    //     52,
    //     53,
    //     54,
    //     55,
    //     56,
    //     57,
    //     58,
    //     59,
    //     60,
    //     61,
    //     62,
    //     63,
    //     64,
    //     65,
    //     66,
    //     67,
    //     68,
    //     69,
    //     70,
    //     71,
    //     72,
    //     73,
    //     74,
    //     75,
    //     76,
    //     77,
    //     78,
    //     79,
    //     80,
    //     81,
    //     82,
    //     83,
    //     84,
    //     85,
    //     86,
    //     87,
    //     88,
    //     89,
    //     90,
    //     91,
    //     92,
    //     93,
    //     94,
    //     95,
    //     96,
    //     97,
    //     98,
    //     99,
    //     100,
    //     101,
    //     102,
    //     103,
    //     104,
    //     105,
    //     106,
    //     107,
    //     108,
    //     109,
    //     110,
    //     111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150
    //   ]
    // }
  ]
  // "legends": [{ "fill": "color", "symbolType": "square", "title": "type" }]
}
